"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var styled_normalize_1 = require("styled-normalize");
require("react-toastify/dist/ReactToastify.min.css");
/**
 * Provides the default styling for our apps.
 */
exports.default = design_library_1.createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  html, body {\n    height: 100%;\n    font-size: 10px;\n  }\n\n  .Toastify__toast {\n    border-radius: 5px !important;\n    padding: 20px !important;\n  }\n\n  /* Fix notification behind the modal */\n  .Toastify__toast-container {\n    z-index: 999999999 !important;\n  }\n\n  div, h1, h2, h3, h4, h5, h5, h6, p, a {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n"], ["\n  ", "\n\n  html, body {\n    height: 100%;\n    font-size: 10px;\n  }\n\n  .Toastify__toast {\n    border-radius: 5px !important;\n    padding: 20px !important;\n  }\n\n  /* Fix notification behind the modal */\n  .Toastify__toast-container {\n    z-index: 999999999 !important;\n  }\n\n  div, h1, h2, h3, h4, h5, h5, h6, p, a {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n"])), styled_normalize_1.default);
var templateObject_1;

import { styled } from "@envisioning/design-library"
import media from "~/media"

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  align-items: center;

  ${media.query.desktop`
    padding-top: 15px;
    padding-bottom: 20px;
  `}
`

export default ButtonWrapper

import * as React from "react"
import CreateSurveyForm from "../survey/forms/CreateSurveyForm"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"

export const SurveyAdminCreate = (props: RouteComponentProps) => {
  return (
    <CreateSurveyForm
      onSuccess={message => {
        props.history.push(`/admin`)
        toast.success(message)
      }}
    />
  )
}

import { observable, action, computed } from "mobx"
const shuffle = require("lodash/shuffle")

interface InitPayload {
  surveyId: string
  technologyIds: string[]
  filterByBoard: string
}

export class SurveyState {
  @observable
  initialized: boolean = false

  @observable
  started: boolean = false
  /**
   * Store the surveyId for reference
   */
  @observable
  surveyId: string = null
  /**
   * Store the filterByBoard for reference
   */
  @observable
  filterByBoard: string = ""

  /**
   * Store the array of technology ids
   */
  @observable
  technologyIds: string[] = []

  /**
   * The current technologyId being displayed
   */
  @observable
  techId: string = null

  /**
   * The modal state to show/hide
   */
  @observable
  isModalOpen: boolean = false

  /**
   * The modal state to flip the card
   */
  @observable
  isModalFlipped: boolean = false

  /**
   * The settings modal to show/hide
   */
  @observable
  isSettingsOpen: boolean = false

  @observable
  isAnswering: boolean = false

  @action
  init = ({ surveyId, technologyIds, filterByBoard }: InitPayload): void => {
    if (!this.initialized) {
      this.surveyId = surveyId
      this.filterByBoard = filterByBoard
      this.technologyIds = technologyIds
      this.initialized = true
    }

    // debug
    // this.isModalFlipped = true
    // this.isModalOpen = true
    // this.techId = this.technologyIds[0]
    // this.startSurvey()
  }

  @action
  flipModal = (): void => {
    this.isModalFlipped = !this.isModalFlipped
  }

  @action
  selectTechnology = (techId: string): void => {
    this.techId = techId
  }

  @action
  selectNextTechnology = (): void => {
    this.techId = this.technologyIds[this.navigationIndexes.next]
  }

  @action
  selectPreviousTechnology = (): void => {
    this.techId = this.technologyIds[this.navigationIndexes.previous]
  }

  @action
  toggleModal = (): void => {
    this.isModalOpen = !this.isModalOpen
  }

  @action
  toggleSettings = (): void => {
    this.isSettingsOpen = !this.isSettingsOpen
  }

  @action
  startSurvey = (): void => {
    this.started = true
  }

  @action
  stopSurvey = (): void => {
    this.started = false
  }

  @action
  setIds = (technologyIds: string[]): void => {
    this.technologyIds = technologyIds
  }

  @computed
  get navigationIndexes() {
    const currentIndex = this.technologyIds.findIndex(id => id === this.techId)

    if (currentIndex === -1) {
      return {
        current: 0,
        next: 0,
        previous: 0
      }
    }

    const nextIndex = this.technologyIds.length - 1 === currentIndex ? 0 : currentIndex + 1
    const previousIndex = currentIndex === 0 ? this.technologyIds.length - 1 : currentIndex - 1

    return {
      current: currentIndex,
      next: nextIndex,
      previous: previousIndex
    }
  }

  @computed
  get currentTechnologyNumber() {
    return this.technologyIds.findIndex(id => id === this.techId) + 1
  }
}

export const surveyState = new SurveyState()

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = firstDefined;

/**
 * Returns the first argument that is not undefined
 * @param {...*} values a list of arguments of any type
 * @returns {*}
 * @protected
 */
function firstDefined() {
  for (var i = 0; i < arguments.length; i++) {
    if ((i < 0 || arguments.length <= i ? undefined : arguments[i]) !== undefined) return i < 0 || arguments.length <= i ? undefined : arguments[i];
  }

  return undefined;
}
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var use_media_1 = require("use-media");
var logo = require("../../../../../assets/logo-light.png");
var responsive_1 = require("../../../../util/responsive");
var Header_styles_1 = require("./Header.styles");
var Header = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, lastLocation = _a.lastLocation, children = _a.children, props = __rest(_a, ["title", "subtitle", "lastLocation", "children"]);
    var isDesktop = use_media_1.useMedia({ minWidth: responsive_1.breakpoints.desktop });
    return (React.createElement(Header_styles_1.HeaderWrapper, __assign({}, props),
        !isDesktop && (React.createElement(Header_styles_1.HeaderLogoWrapper, null,
            React.createElement(Header_styles_1.HeaderLogo, { src: logo }))),
        React.createElement(Header_styles_1.HeaderContext, null,
            React.createElement(Header_styles_1.HeaderTitle, null, title),
            subtitle && React.createElement(Header_styles_1.HeaderSubtitle, null, subtitle)),
        children));
};
// <Button onClick={logout}>Logout</Button>}
exports.default = Object.assign(Header, {
    Workgroup: Header_styles_1.HeaderTitle,
    User: Header_styles_1.HeaderUser
});

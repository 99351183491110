import { useMedia } from "use-media"
import { Breakpoints } from "./types"
import { css } from "@envisioning/design-library"
import { CSSObject } from "styled-components"
import { $TSFixMe } from "@envisioning/design-library/dist/src/types"

type MediaCSS = { [K in keyof Breakpoints]: $TSFixMe }

class Media {
  breakpoints: Breakpoints
  query: MediaCSS

  constructor(breakpoints: Breakpoints) {
    this.breakpoints = breakpoints

    this.query = Object.keys(this.breakpoints).reduce((accumulator, label) => {
      if (label === "mobile") {
        accumulator[label] = (first: CSSObject, ...rest) => css`
          @media (max-width: ${this.breakpoints[label]}px) {
            ${css(first, ...rest)};
          }
        `
        return accumulator
      }

      accumulator[label] = (first: CSSObject, ...rest) => css`
        @media (min-width: ${this.breakpoints[label]}px) {
          ${css(first, ...rest)};
        }
      `
      return accumulator
    }, {}) as any
  }

  public isMobile = () => useMedia({ maxWidth: this.breakpoints.mobile })
  public isTablet = () => useMedia({ minWidth: this.breakpoints.tablet })
  public isDesktop = () => useMedia({ minWidth: this.breakpoints.desktop })
  public isChrome = () => navigator.userAgent.indexOf("Chrome") != -1
  public isFirefox = () => navigator.userAgent.indexOf("Firefox") != -1
  public isSafari = () => navigator.userAgent.indexOf("Safari") != -1
  public isOpera = () => navigator.userAgent.indexOf("Opera") != -1
}

export default new Media({ mobile: 576, tablet: 577, desktop: 992, wide: 1200 })

import * as React from "react"
import * as Sentry from "@sentry/browser"
import { PureQueryOptions } from "apollo-client"
import { toast } from "react-toastify"
import { useUpdateSurveyMutation, UpdateSurveyDocument } from "~/graphql"
import { PanelWrapper } from "./MetricForm"
import { PanelCollapsable } from "../PanelCollapsable"
import SurveyForm from "./SurveyForm"

interface Props {
  survey?: any
  refetchQueries?: PureQueryOptions[]
  onSuccess: (message: string) => void
}

const EditSurveyForm = ({ survey, refetchQueries, onSuccess }: Props) => {
  const [updateSurvey] = useUpdateSurveyMutation(UpdateSurveyDocument)

  async function submitForm(values: any) {
    try {
      await updateSurvey({
        variables: {
          input: values
        },
        refetchQueries
      })
      onSuccess("Edited successfully")
    } catch (error) {
      toast.error("There's something wrong")
      Sentry.configureScope(scope => {
        scope.setTag("page_locale", "edit-survey")
      })
      Sentry.captureException(error)
      console.log("error", error)
    }
  }

  return (
    <PanelWrapper>
      <PanelCollapsable startCollapsed={true} title={`Survey - ${survey.name}`}>
        <SurveyForm survey={survey} key={survey.id} submitForm={submitForm} mode={"edit"} />
      </PanelCollapsable>
    </PanelWrapper>
  )
}

export default EditSurveyForm

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var Footer = design_library_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: white;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: lighter;\n  font-size: 12px;\n  color: ", ";\n  grid-area: footer;\n"], ["\n  background-color: white;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: lighter;\n  font-size: 12px;\n  color: ", ";\n  grid-area: footer;\n"])), function (props) { return props.theme.colors.secondaryColor; });
exports.default = Footer;
var templateObject_1;

import * as React from "react"
import { Image } from "cloudinary-react"

interface Props {
  imageId?: string
  className?: string
  width?: number | string
  height?: number | string
  crop?: "fill" | "scale"
  gravity?: "center"
  quality?: "auto" | "100" | "90" | "80" | "70"
  effect?: string
  onImageLoaded?: (url: string) => void
  style?: React.CSSProperties
}

export default function CloudImage({ imageId, className, ...props }: Props) {
  const baseProps = {
    publicId: imageId || "noimage2",
    className,
    fetchFormat: "auto",
    cloudName: "envisioning",
    width: "auto",
    crop: "fill",
    quality: "auto",
    dpr: "auto",
    responsive: true
  }

  return <Image {...baseProps} {...props} />
}

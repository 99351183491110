import {
  AppProvider,
  Button,
  DarkTheme,
  styled,
  Switch,
  SwitchProps,
  Text,
  TextInput
} from "@envisioning/design-library"
import ReactRouterNavLink from "@ev/platform/lib/modules/shared/components/ReactRouterNavLink"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { RouteComponentProps } from "react-router-dom"
import useKeyPress from "~/hooks/useKeyPress"
import { KeyCodeMap } from "~/types"
import { apolloCache } from "~/utils/apollo-cache"
import media from "../../media"
import { authStore } from "../../store/auth.store"
import CloudImage from "../common/CloudImage"
import Markdown from "../common/Markdown"
import { Params } from "../common/withAuth"
import SvgLogo from "./SvgLogo"

interface SwitchWithLabelProps {
  label: string
  checked?: boolean
  onChange?: SwitchProps["onChange"]
}

const DarkBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.secondaryBg};
  overflow: auto;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: auto;
  padding: 1.5rem;
  padding-top: 3rem;

  ${media.query.desktop`
    width: 600px;
    height: 100%;
  `}
`

const Content = styled.div``

const StyledSvgLogo = styled(SvgLogo)`
  width: 150px;
  min-width: 15rem;
  height: 3rem;
  min-height: 3rem;
  margin-bottom: 2rem;

  ${media.query.desktop`
    margin-bottom: 3rem;
  `}
`

const LoginText = styled.div`
  color: ${props => props.theme.colors.mainColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: left;
  margin-bottom: 4rem;
`

const MainText = styled(Text)`
  max-width: 28rem;
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.goColor};
`

const NameInputWrapper = styled.div`
  margin-bottom: 1rem;
`
const EmailInputWrapper = styled.div`
  margin-bottom: 2rem;
`

const LegalTextLink = styled.a`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.nunito};
  margin-bottom: 2em;
  display: block;
  line-height: 1.6rem;

  ${media.query.desktop`
    margin-bottom: 1.5em;
    font-size: 12px;
  `}
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  ${media.query.desktop`
    margin-bottom: 1.5em;
  `}
`

const SwitchLabel = styled.div<{ checked: boolean }>`
  font-family: "Nunito Sans";
  font-size: 1rem;
  text-transform: uppercase;
  color: ${props => (props.checked ? props.theme.colors.goColor : props.theme.colors.lightGrey)};
  padding-left: 1rem;
`
const SendButton = styled(Button)`
  margin-bottom: 2rem;

  ${media.query.desktop`
    margin-bottom: 7rem;
  `}
`

const FooterLabel = styled.div`
  font-family: ${props => props.theme.fonts.nunito};
`
const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;

  ${media.query.desktop`
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  `}

  ${FooterLabel}:nth-child(1) {
    color: ${props => props.theme.colors.mainColor};
    font-size: 8px;
    text-transform: uppercase;
    margin-bottom: 8px;

    ${media.query.desktop`
      font-size: 10px;
    `}
  }

  ${FooterLabel}:nth-child(2) {
    color: ${props => props.theme.colors.waterloo};
    font-size: 10px;

    ${media.query.desktop`
      font-size: 12px;
    `}
  }
`

class SwitchWithLabel extends React.Component<SwitchWithLabelProps> {
  render() {
    return (
      <SwitchWrapper>
        <Switch onChange={this.props.onChange as any} checked={this.props.checked} />
        <SwitchLabel checked={this.props.checked}>{this.props.label}</SwitchLabel>
      </SwitchWrapper>
    )
  }
}

const AuthScreen = observer((props: RouteComponentProps<Params>) => {
  // Query params
  const { surveyId } = props.match.params
  const survey = apolloCache.getSurveyInfo(surveyId)

  // State
  const isDesktop = media.isDesktop()

  // --> Form fields
  const [email, setEmail] = React.useState<string>("")
  const [name, setName] = React.useState<string>("")

  // --> UI loading states
  const [isWaitingConfirmation, setIsWaitingConfirmation] = React.useState<boolean>(false)
  const [isResendingEmail, setIsResendingEmail] = React.useState<boolean>(false)
  const [isEmailVerified, setEmailVerified] = React.useState<boolean>(false)
  const [acceptCookies, setAcceptCookies] = React.useState(false)

  // --> If the user already ask for confirmation email, we allow to resend only once
  const [isEmailResentOnce, setIsEmailResentOnce] = React.useState<boolean>(false)

  // --> Store the verify code
  const [verifyCode, setVerifyCode] = React.useState<string>(null)

  // Button callbacks
  const onNameChange = React.useCallback(value => setName(value), [name])
  const onEmailChange = React.useCallback(value => setEmail(value), [email])

  // Interval fn
  let verifyInterval: number

  // Handle Enter to submit the form
  useKeyPress(KeyCodeMap.Enter, onFormSubmit, [name, email, acceptCookies])

  async function onFormSubmit() {
    if (!acceptCookies || !name || !email) return

    // Request start
    const res = await authStore.postRegister(email, name)
    setVerifyCode(res.verifyCode)

    // Change UI state to confirmation email
    setIsWaitingConfirmation(true)

    // Create a function that will check if the email was verified very X seconds
    if (res.verifyCode && !verifyInterval) {
      verifyInterval = setInterval(async () => {
        try {
          const verifyResult = await authStore.postVerifyEmail(res.verifyCode)
          if (verifyResult.data.message === "Auth success") {
            clearInterval(verifyInterval)
            setEmailVerified(true)
          }
        } catch (err) {
          console.log("is ok")
        }
      }, 10000)
    }
  }

  async function resendConfirmationEmail() {
    setIsResendingEmail(true)
    try {
      await authStore.postResendConfirmation(verifyCode)
      setIsEmailResentOnce(true)
    } catch {
      console.log("error")
    } finally {
      setIsResendingEmail(false)
    }
  }

  function onLogin() {
    props.history.push(`/${surveyId}`)
  }

  const renderAfterVerified = () => (
    <>
      <MainText style={{ marginBottom: 10 }}>Your email was verified</MainText>
      <Button big onClick={onLogin}>
        click here to login
      </Button>
    </>
  )
  const renderWaitingConfirmation = () => (
    <React.Fragment>
      <MainText>
        Please check your e-mail to continue with the login process. If it didn't arrive, you might
        want to check your spam folder.
      </MainText>

      {!isEmailResentOnce ? (
        <Button
          kind="neutral-outline"
          onClick={() => resendConfirmationEmail()}
          disabled={!verifyCode || isResendingEmail}
          loading={isResendingEmail}
        >
          Resend Confirmation Email
        </Button>
      ) : (
        <MainText style={{ fontSize: 14 }}>
          We've just resent you the email. Please check your inbox.
        </MainText>
      )}
    </React.Fragment>
  )
  const renderAuthForm = () => (
    <div style={{ width: "100%" }}>
      {isDesktop && (
        <LoginText style={{ textAlign: "center" }}>
          <Markdown markdown={survey.authPageText} />
        </LoginText>
      )}
      <div style={{ maxWidth: 400, margin: "auto" }}>
        <NameInputWrapper>
          <TextInput
            type="name"
            label="name"
            value={name}
            onChange={onNameChange}
            disabled={authStore.isLoading}
            data-cy-login-name
          />
        </NameInputWrapper>
        <EmailInputWrapper>
          <TextInput
            type="email"
            label="e-mail"
            value={email}
            onChange={onEmailChange}
            disabled={authStore.isLoading}
            data-cy-login-email
          />
        </EmailInputWrapper>
        <SwitchWithLabel
          checked={acceptCookies}
          onChange={val => setAcceptCookies(val)}
          label={"ACCEPT ALL COOKIES"}
        />
        <LegalTextLink
          href="https://docs.google.com/document/d/10LoFstir1IMJ-S_UrgrpfvatRhNys1fSaeq1MdcGVb8/edit?usp=sharing"
          target="blank"
        >
          By checking “ACCEPT ALL COOKIES” you agree to the storing of cookies on your device to
          enhance device to enhance site navigation.
        </LegalTextLink>
        <SendButton
          big
          disabled={!acceptCookies}
          loading={authStore.isLoading}
          fullWidth
          onClick={onFormSubmit}
        >
          send invitation
        </SendButton>
      </div>
    </div>
  )

  return (
    <AppProvider theme={DarkTheme} linkComponent={ReactRouterNavLink}>
      <DarkBackground>
        <Wrapper>
          <StyledSvgLogo />
          <Content>
            <CloudImage
              imageId={survey.authPageImageId}
              width={150}
              style={{ marginBottom: isDesktop ? 20 : 10 }}
            />

            {isEmailVerified
              ? renderAfterVerified()
              : isWaitingConfirmation
              ? renderWaitingConfirmation()
              : renderAuthForm()}
          </Content>
          <Footer>
            <FooterLabel>EV Survey App</FooterLabel>
            <FooterLabel>
              powered by
              <span style={{ color: "white" }}> envisioning.io</span>
            </FooterLabel>
          </Footer>
        </Wrapper>
      </DarkBackground>
    </AppProvider>
  )
})

export default AuthScreen

import { Button, Card, Icon, Panel, styled } from "@envisioning/design-library"
import * as React from "react"
import { Col, Container, Row } from "react-grid-system"
import { CARD_WORKGROUP_LABEL } from "~/constants"
import media from "~/media"
import { surveyState } from "~/store/survey.store"
import { apolloCache } from "~/utils/apollo-cache"
import boardIntersection from "../../utils/boardIntersection"
import CloudImage from "../common/CloudImage"
import Markdown from "../common/Markdown"
import { WithAuthComponentProps } from "../common/withAuth"
const first = require("lodash/first")

const LeftColWrapper = styled.div`
  width: 25rem;
  position: relative;
  margin: auto;

  ${media.query.desktop`
    position: fixed;
  `}
`

const StyledLeftCol = styled(Col)`
  margin-bottom: 30px;
  ${media.query.desktop`
    max-width: 280px !important;
  `}
`

const StyledRightCol = styled(Col)`
  margin-bottom: 60px;
`
const StartButton = styled(Button)`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 60px;
  z-index: 900;

  ${media.query.desktop`
    position: unset;
    margin-top: 15px;
  `}
`

export const EditButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 20;
  opacity: var(--edit-button-opacity);
  visibility: var(--edit-button-visibility);
  transition: opacity 250ms ease;
  &:hover {
    cursor: pointer;
  }
`

export const CarouselContainer = styled.div`
  position: relative;
  height: auto;
  width: auto;
  background-color: white;
  --edit-button-opacity: 0;
  --edit-button-visibility: "hidden";
  &:hover {
    --edit-button-opacity: 1;
    --edit-button-visibility: "visible";
  }
`

export const Circle = styled.div<{ filled?: boolean }>`
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  transition: background-color 150ms ease-in-out;
  background-color: ${({ filled, theme }) =>
    filled ? theme.colors.goColor : theme.colors.secondaryColor};
  &:hover {
    cursor: pointer;
  }
`

const TechnologiesPanel = styled(Panel)`
  ${media.query.desktop`
    margin-bottom: 20px;
  `}
`

const TechnologiesIcon = styled(Panel.Header)`
  height: 100px;
  border: none;
`

const TechnologiesCount = styled(Panel.Body)`
  padding-top: 0;
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  display: block;
  font-family: ${({ theme }) => theme.fonts.nunito};
  color: ${({ theme }) => theme.colors.secondaryColor};
  span {
    color: ${({ theme }) => theme.colors.goColor};
  }
`

const MetricsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const MetricsListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 0;
`

const MetricLabel = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-family: ${({ theme }) => theme.fonts.nunito};
  margin-left: 10px;
`

const StyledCard = styled(Card.Image)`
  div {
    background: none;
  }
`

const SurveyIntro = (props: WithAuthComponentProps) => {
  const { surveyId } = props.match.params
  const filterByBoard = props.search.filterByBoard

  const { survey, board } = apolloCache.getSurvey(surveyId, filterByBoard ? filterByBoard : "")
  const isDesktop = media.isDesktop()

  /**
   * Redirects to the /survey route and opens the modal
   */
  function onStartSurvey() {
    const firstId = first(surveyState.technologyIds)
    surveyState.selectTechnology(firstId)
    surveyState.toggleModal()
  }

  // Configuration from db
  const extraCardProps = survey.labelsOnImageEnabled
    ? {
        label: survey.workgroupName,
        secondaryLabel: CARD_WORKGROUP_LABEL,
        forceDarkLabel: survey.darkLabelsOnImageEnabled
      }
    : null

  return (
    <Container fluid style={{ paddingTop: isDesktop ? 40 : 0 }}>
      <Row nogutter>
        <StyledLeftCol xl={3} lg={4} md={12} sm={12}>
          <LeftColWrapper>
            <StyledCard
              {...extraCardProps}
              imageComponent={<CloudImage width={250} imageId={survey.imageId} />}
              height={300}
              noFilter
            />
            <StartButton minWidth={250} big onClick={onStartSurvey}>
              start survey
            </StartButton>
          </LeftColWrapper>
        </StyledLeftCol>
        <StyledRightCol xl={9} lg={8} md={12} sm={12}>
          <Row>
            <Col xl={8} lg={12}>
              <Panel>
                <Panel.Body>
                  <Markdown markdown={survey.description} />
                </Panel.Body>
              </Panel>
            </Col>
            <Col xl={4} lg={12}>
              <TechnologiesPanel>
                <TechnologiesIcon>
                  <Icon name={"rocket25"} size={50} style={{ margin: "auto" }} />
                </TechnologiesIcon>
                <TechnologiesCount>
                  {/* Technologies coming from db */}
                  <span>
                    {boardIntersection({ board, technologies: survey.technologies }).length}
                  </span>{" "}
                  Technologies
                </TechnologiesCount>
              </TechnologiesPanel>
              <Panel>
                <Panel.Header icon="ruler25">Metrics</Panel.Header>
                <Panel.Body noPadding>
                  <MetricsList>
                    {/* Metrics coming from db */}
                    {survey.metrics.map(metric => (
                      <MetricsListItem key={metric.id}>
                        <Icon name={"locker15"} size={20} color="stopColor" />
                        <MetricLabel>{metric.name}</MetricLabel>
                      </MetricsListItem>
                    ))}
                  </MetricsList>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </StyledRightCol>
      </Row>
    </Container>
  )
}

export default SurveyIntro

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
/**
 * Use this component to convert all Link components to NavLink from react-router-dom
 * It should be passed to AppContext from the design-library
 * After setting it up, when a <Link> is imported from the design-library, it will be
 * converted to this component.
 */
var ReactRouterNavLink = function (_a) {
    var url = _a.url, children = _a.children, className = _a.className, exact = _a.exact;
    return (React.createElement(react_router_dom_1.NavLink, { to: url, children: children, className: className, exact: exact, style: {
            textDecoration: "none"
        } }));
};
exports.default = ReactRouterNavLink;

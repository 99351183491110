import { apolloClient } from "./apollo-client"
import {
  GetSurveyQuery,
  GetSurveyQueryVariables,
  GetSurveyDocument,
  TechnologyFieldsFragmentDoc,
  TechnologySurvey,
  MetricAnswer,
  GetSurveyInfoQuery,
  GetSurveyInfoDocument,
  GetSurveyInfoQueryVariables
} from "../graphql"

const getSurvey = (surveyId: string, boardId = "") => {
  boardId = boardId ? boardId : ""
  const data = apolloClient.readQuery<GetSurveyQuery, GetSurveyQueryVariables>({
    query: GetSurveyDocument,
    variables: {
      id: surveyId,
      boardId: boardId
    }
  })

  return data ? data : { survey: null, board: null }
}

const getSurveyInfo = (surveyId: string) => {
  const data = apolloClient.readQuery<GetSurveyInfoQuery, GetSurveyInfoQueryVariables>({
    query: GetSurveyInfoDocument,
    variables: {
      id: surveyId
    }
  })

  return data ? data.survey : null
}

const getTechnology = (technologyId: string) => {
  return apolloClient.readFragment<TechnologySurvey>({
    fragment: TechnologyFieldsFragmentDoc,
    id: technologyId,
    fragmentName: "TechnologyFields"
  })
}

const updateTechnology = (technologyId: string, data: Partial<TechnologySurvey>) => {
  return apolloClient.writeFragment({
    fragment: TechnologyFieldsFragmentDoc,
    fragmentName: "TechnologyFields",
    id: technologyId,
    data
  })
}

const updateTechnologyAnswers = (technologyId: string, answer: MetricAnswer) => {
  const techFragment = getTechnology(technologyId)
  const answerIndex = techFragment.viewerAnswers.findIndex(
    a => a.question.id === answer.question.id
  )

  if (answerIndex !== -1) {
    techFragment.viewerAnswers[answerIndex].option = answer.option
  } else {
    const newArray = [...techFragment.viewerAnswers, answer]

    return updateTechnology(technologyId, {
      ...techFragment,
      viewerAnswers: newArray
    })
  }
}

export const apolloCache = {
  getSurvey,
  getSurveyInfo,
  getTechnology,
  updateTechnology,
  updateTechnologyAnswers
}

import * as React from "react"
import { Button, Icon, Loader, styled, Text } from "@envisioning/design-library"
import { RouteComponentProps, withRouter } from "react-router"
import { toast } from "react-toastify"
import Modal from "styled-react-modal"
import { ResetSurveyAnswersComponent, useGetSurveyForAdminQuery } from "~/graphql"
import media from "~/media"
import { surveyState } from "~/store/survey.store"
import { Params } from "../common/withAuth"
import EditSurveyForm from "./forms/EditSurveyForm"

const StyledModal = Modal.styled`
  background-color: transparent;
  width: 85%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  opacity: ${props => props.opacity};
  transition: opacity 500ms;
  position: fixed;
  padding: 0;
  top: 0;
  right: 0;
  left: auto;
  outline: none;

  ${media.query.desktop`
    width: 60rem;
  `}
`

const ModalWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
`

const ModalHeader = styled.div`
  background-color: ${props => props.theme.colors.secondaryBg};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding: 0.8rem 1.2rem;
`

const FormWrapper = styled.div`
  padding: 2.5rem;
  overflow-y: auto;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 1.2rem;
  padding-top: 0.4rem;

  &:hover {
    fill: ${props => props.theme.colors.goColor} !important;
  }

  ${media.query.desktop`
    padding-top: 0;
  `}
`

type Props = RouteComponentProps<Params> & {
  isOpen: boolean
  surveyId: string
}

const SettingsModal = (props: Props) => {
  const { data, loading } = useGetSurveyForAdminQuery({
    variables: {
      id: props.surveyId
    }
  })

  const survey = data.survey
  const [opacity, setOpacity] = React.useState(0)

  function afterOpen() {
    setOpacity(1)
  }

  return (
    <StyledModal
      isOpen={props.isOpen}
      afterOpen={afterOpen}
      onEscapeKeydown={() => surveyState.toggleSettings()}
      opacity={opacity}
      backgroundProps={{
        opacity
      }}
    >
      <ModalWrapper>
        <ModalHeader>
          <Text style={{ marginLeft: 12 }}>Edit settings</Text>
          <CloseIcon name="x25" size={25} onClick={() => surveyState.toggleSettings()} />
        </ModalHeader>
        <FormWrapper>
          {!loading ? (
            <EditSurveyForm
              survey={survey}
              onSuccess={message => {
                toast.success(message)
              }}
            />
          ) : (
            <Loader type="ball-beat" color="waitColor" />
          )}
          <ResetSurveyAnswersComponent>
            {(resetSurveyAnswers, result) => (
              <Button
                kind="cancel"
                fullWidth
                loading={result.loading}
                disabled={result.loading}
                onClick={async () => {
                  const confirm = window.confirm(
                    "Are you sure you want to reset this survey answers? This action can't be undone."
                  )
                  if (confirm) {
                    try {
                      await resetSurveyAnswers({
                        variables: {
                          surveyId: props.surveyId
                        }
                      })
                      alert("Survey Answers deleted!")
                    } catch (err) {
                      alert("Error trying to delete answers. Check logs for stackstrace.")
                      console.log(err)
                    }
                  }
                }}
              >
                delete all answers
              </Button>
            )}
          </ResetSurveyAnswersComponent>
        </FormWrapper>
      </ModalWrapper>
    </StyledModal>
  )
}

export default withRouter(SettingsModal)

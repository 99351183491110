import { Avatar, Button, styled, Text } from "@envisioning/design-library"
import LoadingView from "@ev/platform/lib/components/LoadingView"
import Content from "@ev/platform/lib/modules/layout/components/Content/Content"
import Footer from "@ev/platform/lib/modules/layout/components/Footer/Footer"
import Header from "@ev/platform/lib/modules/layout/components/Header/Header"
import Navbar from "@ev/platform/lib/modules/layout/components/Navbar/Navbar"
import { IconLink } from "@ev/platform/lib/modules/layout/components/Navbar/Navbar.styles"
import { ResponsiveGridLayout } from "@ev/platform/lib/modules/layout/layouts/RootLayout"
import * as React from "react"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { CONTENT_BODY_ID } from "../../constants"
import { useGetSurveyQuery } from "../../graphql"
import { useLogoutMutation } from "~/graphql"
import media from "../../media"
import routerConfig from "../../router.config"
import { surveyState } from "../../store/survey.store"
import { AdminOnly } from "./AdminOnly"
import NotFoundScreen from "./NotFoundScreen"
import boardIntersection from "../../utils/boardIntersection"
import { useURLSearchParams, SearchParamName } from "../../hooks/useURLSearchParams"

const shuffle = require("lodash/shuffle")

export type SearchParams = { [key in SearchParamName]?: string }

export type Params = {
  surveyId: string
}

export interface WithAuthComponentProps<PageParams = {}>
  extends RouteComponentProps<Params & PageParams> {
  search: SearchParams
}

const EmailLabel = styled.div`
  color: ${props => props.theme.colors.secondaryColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 10px;
  margin-right: 10px;
  font-weight: bold;
  right: 55px;
`

const withAuth = (Component: React.ComponentType<WithAuthComponentProps>) => {
  return (props: RouteComponentProps<Params>) => {
    const { surveyId } = props.match.params
    const { searchParams } = useURLSearchParams(props.location)

    // Parse all search params before to forward to components
    const search: SearchParams = {
      filterByBoard: searchParams("filterByBoard")
    }

    console.log("search", search)

    const isMobile = media.isMobile()
    const isDesktop = media.isDesktop()

    const { data, error, loading } = useGetSurveyQuery({
      variables: {
        id: surveyId,
        boardId: search.filterByBoard || ""
      }
    })

    const [logout] = useLogoutMutation()

    if (loading) {
      return (
        <LoadingView type="line-scale">
          <Text
            style={{
              textAlign: "center",
              marginTop: 40,
              fontWeight: "bold",
              fontSize: 16
            }}
          >
            Preparing the survey experience...
          </Text>
        </LoadingView>
      )
    }

    if (error) {
      if ((error.graphQLErrors[0].message as any).error === "Unauthorized")
        console.log("Unauthorized")
      return <Redirect to={`/${surveyId}/auth`} />
    }

    // If survey not found
    if (!data.survey) {
      // return <Redirect to="/" />
      return <NotFoundScreen />
    }
    // Init
    surveyState.init({
      surveyId: surveyId,
      filterByBoard: search.filterByBoard,
      technologyIds: shuffle(
        boardIntersection({ board: data.board, technologies: data.survey.technologies })
      )
        .sort(t => t.viewerAnswers.length)
        .map(t => t.id)
    })

    const navLinks = [
      {
        icon: routerConfig.home.icon,
        to: `/${surveyId}`,
        exact: true
      },
      {
        icon: routerConfig.survey.icon,
        to: `/${surveyId}/survey`,
        exact: true
      },
      {
        icon: routerConfig.metric.icon,
        to: `/${surveyId}/metric`,
        exact: true
      }
    ]

    if (data.viewer.isAdmin && isDesktop) {
      navLinks.push({
        component: (
          <AdminOnly>
            <div
              style={{ marginTop: 20, cursor: "pointer" }}
              onClick={() => surveyState.toggleSettings()}
            >
              <IconLink name={"gear25"} size={25} color="secondaryColor" data-cy-survey-gearIcon />
            </div>
          </AdminOnly>
        )
      } as any)
    }

    // TODO: Make it better
    const pathNames = {
      survey: "Survey",
      metric: "Metric",
      settings: "Settings"
    }
    const pathnameSplitted = props.location.pathname.split("/")
    const subtitle = pathnameSplitted.length === 3 ? pathNames[pathnameSplitted[2]] : "Home"
    // ----------------------

    return (
      <ResponsiveGridLayout>
        <Navbar links={navLinks} appName={process.env.REACT_APP_NAME} />

        <Header title={data.survey.name} subtitle={subtitle}>
          <Header.User>
            <EmailLabel>{data.viewer.display}</EmailLabel>
            <Avatar
              size={isMobile ? 28 : 34}
              shape="round"
              src={`https://res.cloudinary.com/envisioning/image/upload/c_scale,r_0,w_30/v1556518170/${
                data.survey.workgroupImageId
              }`}
              style={{ minWidth: 30, marginRight: 22 }}
            />
            <Button
              kind={"cancel"}
              onClick={async () => {
                await logout()
                props.history.push(`/`)
              }}
              style={{ minWidth: 80 }}
            >
              logout
            </Button>
          </Header.User>
        </Header>

        <Content>
          <Content.Body id={CONTENT_BODY_ID}>
            <React.Suspense fallback={"Loading..."}>
              <Component search={search} {...props} />
            </React.Suspense>
          </Content.Body>
        </Content>
        {isDesktop && <Footer>Envisioning 2.0</Footer>}
      </ResponsiveGridLayout>
    )
  }
}

export default withAuth

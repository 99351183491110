import * as React from "react"
import { RouteConfig } from "./types"
import SurveyIntro from "./components/survey/SurveyIntro"
import SurveyList from "./components/survey/SurveyList"
import SettingsModal from "./components/survey/SettingsModal"
import { SvgIconName } from "@envisioning/design-library"

export type SurveyRouteConfig = {
  to?: string
  path?: string
  title?: string
  icon?: SvgIconName
}

type RouterConfig = {
  admin: SurveyRouteConfig
  adminSurveyCreate: SurveyRouteConfig
  adminSurveyEdit: SurveyRouteConfig
  adminMetrics: SurveyRouteConfig
  adminMetricsCreate: SurveyRouteConfig
  adminMetricsEdit: SurveyRouteConfig
  home: RouteConfig
  survey: RouteConfig
  metric: RouteConfig
  settings: RouteConfig
}

const routerConfig: RouterConfig = {
  admin: {
    path: "/admin",
    title: "Admin"
  },
  adminSurveyCreate: {
    path: "/admin/create-survey"
  },
  adminSurveyEdit: {
    path: "/admin/survey/:surveyId"
  },
  adminMetrics: {
    path: "/admin/metrics"
  },
  adminMetricsCreate: {
    path: "/admin/create-metric"
  },
  adminMetricsEdit: {
    path: "/admin/metrics/:metricId"
  },
  home: {
    icon: "home25",
    to: "/:surveyId",
    component: SurveyIntro
  },
  survey: {
    icon: "survey25",
    to: "/:surveyId/survey",
    component: SurveyList
  },
  metric: {
    icon: "ruler25",
    to: "/:surveyId/metric",
    component: () => <div> metric </div>
  },
  settings: {
    icon: "gear25",
    to: "/:surveyId/settings",
    component: SettingsModal
  }
}

export default routerConfig

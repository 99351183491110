import * as React from "react"
import { RouteComponentProps, Redirect } from "react-router-dom"
import { useGetSurveyQuery, useGetSurveyInfoQuery } from "../../graphql"
import NotFoundScreen from "./NotFoundScreen"
import { Params } from "./withAuth"

/**
 * Only checks if the survey exists and hande routing.
 */
const withSurvey = (Component: React.ComponentType<RouteComponentProps<Params>>) => {
  return (props: RouteComponentProps<Params>) => {
    const { surveyId } = props.match.params
    const { data, error, loading } = useGetSurveyInfoQuery({
      variables: {
        id: surveyId
      }
    })

    if (loading) {
      return null
    }

    if (error) {
      throw new Error(error.stack)
    }

    // If survey not found
    if (!data.survey) {
      // return <Redirect to="/" />
      return <NotFoundScreen />
    }

    return <Component {...props} />
  }
}

export default withSurvey

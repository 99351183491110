import * as React from "react"
import * as Sentry from "@sentry/browser"
import { Panel } from "@envisioning/design-library"
import { useCreateMetricMutation, CreateMetricDocument } from "~/graphql"
import MetricForm from "./MetricForm"
import { toast } from "react-toastify"
interface Props {
  onSuccess: (message: string) => void
}

function CreateMetricForm({ onSuccess }: Props) {
  const [createMetric] = useCreateMetricMutation(CreateMetricDocument)

  async function submitForm(values: any) {
    try {
      await createMetric({
        variables: {
          input: values
        }
      })
      onSuccess("Created successfully")
    } catch (error) {
      toast.error("There's something wrong")
      Sentry.configureScope(scope => {
        scope.setTag("page_locale", "create-metric")
      })
      Sentry.captureException(error)
      console.log(error)
    }
  }

  return (
    <Panel style={{ maxWidth: 1000, margin: "auto" }}>
      <Panel.Header>create metric</Panel.Header>
      <Panel.Body>
        <MetricForm submitForm={submitForm} mode={"create"} />
      </Panel.Body>
    </Panel>
  )
}

export default CreateMetricForm

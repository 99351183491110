import {
  Button,
  Card,
  CardImageProps,
  Icon,
  ListItem,
  styled,
  Text
} from "@envisioning/design-library"
import { Label as CardImageLabel } from "@envisioning/design-library/dist/src/components/Card/CardImage.styles"
import { observer } from "mobx-react-lite"
import * as React from "react"
import Flippy, { BackSide, FrontSide } from "react-flippy"
import { RouteComponentProps, withRouter } from "react-router"
import { Element, scroller } from "react-scroll"
import { animated, useTransition } from "react-spring"
import Modal, { BaseModalBackground } from "styled-react-modal"
import { TECHNOLOGY_ITEM_LABEL } from "~/constants"
import { useSubmitSurveyAnswerMutation, SubmitSurveyAnswerDocument } from "~/graphql"
import { delay } from "~/lib"
import media from "~/media"
import { surveyState } from "~/store/survey.store"
import { KeyCodeMap } from "~/types"
import { apolloCache } from "~/utils/apollo-cache"
import ButtonKey from "../common/ButtonKey"
import CloudImage from "../common/CloudImage"
import { Params } from "../common/withAuth"
import ButtonWrapper from "./ButtonWrapper"
import SurveyFinished from "./SurveyFinished"
import SurveyMetricQuestion from "./SurveyMetricQuestion"
import SurveyTechnology from "./SurveyTechnology"
import boardIntersection from "../../utils/boardIntersection"

export const FadingBackground = styled(BaseModalBackground)`
  height: 100%;
  background-color: ${props => props.color};
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
  z-index: 1000;
`

const StyledModal = Modal.styled`
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  opacity: ${props => props.opacity};
  transition: opacity 500ms;
  border-radius: 6px;
  padding: 15px;

  ${media.query.desktop`
    width: 70rem;
    height: calc(100% - 40px);
    padding: 0;
  `}
`

const ModalHeader = styled(Text)`
  margin: 0;
  color: ${props => props.theme.colors.goColor};
`

const OuterButtonContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;

  ${media.query.desktop`
    height: 8em;
    padding-top: 0;
  `}
`

const OuterTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 10px 10px;

  ${media.query.desktop`
    padding: 2rem;
  `}
`

const AnimatedDiv = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: inherit;
  will-change: transform, opacity;
  left: 0px;
`

const ModalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FrontContentWrapper = styled.div`
  height: calc(100% - 5rem);
  overflow: auto;
  margin: 0;
  padding-bottom: 8px;
`

const BackContentWrapper = styled.div`
  height: calc(100% - 12rem);
  padding: 0px 5px 8px 5px;
  margin: 0;
  ${media.query.desktop`
    height: calc(100% - 8.1rem);
  `}
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 1.2rem;
  padding-top: 0.4rem;

  &:hover {
    fill: ${props => props.theme.colors.goColor} !important;
  }

  ${media.query.desktop`
    padding-top: 0;
  `}
`

const StyledListItem = styled(ListItem)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 0;
  transition: background-color 0.3s ease;
`

const ArrowButton = styled(Button)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
  div {
    fill: ${props => props.theme.colors.goColor} !important;
  }
`

const TechnologiesCount = styled.div`
  color: ${props => props.theme.colors.goColor};
  font-size: 1.2rem;
  padding: 0 1rem;
`

const StepsList = styled.div`
  display: flex;
  align-items: center;
`

const StepsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`

export const Circle = styled.div<{ filled?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: all 200ms;
  background-color: ${({ filled, theme }) =>
    filled ? theme.colors.goColor : theme.colors.disabledColor};
  &:hover {
    cursor: pointer;
  }
`

export const CircleAround = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transition: all 200ms ease;
  position: absolute;
  border: 1px solid ${props => props.theme.colors.goColor};
  &:hover {
    cursor: pointer;
  }
`

type Props = RouteComponentProps<Params>

const SurveyModal = observer((props: Props) => {
  const surveyId = surveyState.surveyId
  const filterByBoard = surveyState.filterByBoard
  const { survey, board } = apolloCache.getSurvey(surveyId, filterByBoard)
  const technologiesCount = boardIntersection({ board, technologies: survey.technologies }).length
  const [submitAnswerMutation] = useSubmitSurveyAnswerMutation(SubmitSurveyAnswerDocument)
  const currentTechnology = apolloCache.getTechnology(surveyState.techId)

  // State
  const [opacity, setOpacity] = React.useState(0)
  const [stepIndex, setStepIndex] = React.useState(0)
  const [transitionStyle, setTransitionStyle] = React.useState("next")
  const [isImageLoading, setIsImageLoading] = React.useState(false)
  const isDesktop = media.isDesktop()
  const isFirefox = media.isFirefox()
  const cardImageHeight = media.isMobile() ? 210 : 300

  const answeredCount = React.useMemo(() => {
    return boardIntersection({ board, technologies: survey.technologies }).filter(
      t => t.viewerAnswers.length === survey.questionsCount
    ).length
  }, [survey, board])

  const isAllAnswersComplete = React.useMemo(() => {
    return (
      currentTechnology &&
      currentTechnology.viewerAnswers &&
      currentTechnology.viewerAnswers.length === survey.questionsCount
    )
  }, [survey, currentTechnology])

  // I pulled from https://codesandbox.io/embed/1y3yyqpq7q
  const transitions = useTransition(stepIndex, p => p, {
    from: {
      opacity: 0,
      transform: `translate3d(${transitionStyle === "next" ? "100%" : "-100%"},0,0)`
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)"
    },
    leave: {
      opacity: 0,
      transform: `translate3d(${transitionStyle === "next" ? "-50%" : "50%"},0,0)`
    }
  })

  React.useEffect(() => {
    window.addEventListener("keydown", onKeyUp)
    return () => {
      window.removeEventListener("keydown", onKeyUp)
    }
  })

  const inlineStyles = {
    flippyContainer: {
      height: !isDesktop ? "calc(100% - 9rem)" : "calc(100% - 13rem)"
    },
    flippyCard: {
      background: "white",
      border: "1px solid #EBEBF0",
      borderRadius: "4px",
      boxShadow: "0px 1px 8px 2px rgba(0, 0, 0, 0.1)",
      padding: 2,
      // Fix scroll bug on Firefox
      overflow: isFirefox ? "auto" : "hidden",
      scrollbarWidth: "none"
    }
  }

  function startSurvey() {
    surveyState.startSurvey()
    surveyState.flipModal()
  }

  function afterOpen() {
    setOpacity(1)
    delay(500).then(() => {
      const queryString = filterByBoard ? `?filterByBoard=${filterByBoard}` : ""
      if (props.history.location.pathname !== `/${surveyId}/survey`) {
        props.history.push(`/${surveyId}/survey` + queryString)
      }
    })
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      delay(500).then(() => {
        surveyState.stopSurvey()
        if (surveyState.isModalFlipped) {
          surveyState.flipModal()
        }

        resetStep()
        resolve()
      })
    })
  }

  function scrollTo() {
    scroller.scrollTo("scrollElement", {
      duration: 500,
      delay: 50,
      smooth: true,
      containerId: "scrollElementId"
    })
  }

  function nextStep() {
    if (stepIndex < steps.length - 1) {
      setTransitionStyle("next")
      setStepIndex(stepIndex + 1)
    }
  }

  function previousStep() {
    if (stepIndex > 0) {
      setTransitionStyle("back")
      setStepIndex(stepIndex - 1)
    }
  }

  function selectStep(index) {
    stepIndex < steps.length - 1 ? setTransitionStyle("next") : setTransitionStyle("back")
    setStepIndex(index)
  }

  function resetStep() {
    setStepIndex(0)
  }

  function previousTechnology() {
    surveyState.selectPreviousTechnology()
    setIsImageLoading(true)
    scrollTo()
    resetStep()
  }

  function nextTechnology() {
    surveyState.selectNextTechnology()
    surveyState.stopSurvey()
    setIsImageLoading(true)

    if (surveyState.isModalFlipped) {
      surveyState.flipModal()
    }
    scrollTo()
    resetStep()
  }

  // TODO: Improve this logic
  // This was just to get the basic idea working
  const steps: any[] = []

  survey.metrics.forEach(metric => {
    metric.questions.forEach(question => {
      steps.push({
        metric: {
          name: metric.name,
          description: metric.description
        },
        name: metric.name,
        question,
        type: "metric-question"
      })
    })
  })

  // Add a last step for the 'thank you' section
  steps.push({
    type: "finished"
  })

  async function submitAnswer(optionId: string) {
    await submitAnswerMutation({
      variables: {
        input: {
          technologyId: currentTechnology.id,
          optionId: optionId,
          surveyId: survey.id
        }
      },
      update: (_, { data }) => {
        apolloCache.updateTechnologyAnswers(currentTechnology.id, data.submitAnswer)
      }
    })
    nextStep()
  }

  function onKeyUp({ keyCode }) {
    // TODO Refactor onKeyUp
    if (!surveyState.started) {
      switch (keyCode) {
        case KeyCodeMap.ArrowRight:
          nextTechnology()
          break
        case KeyCodeMap.ArrowLeft:
          previousTechnology()
          break
        default:
          break
      }
    }
    if (surveyState.started) {
      switch (keyCode) {
        case KeyCodeMap.Esc:
          surveyState.flipModal()
          break
        default:
          break
      }
    }
  }

  const renderSteps = () => {
    if (!surveyState.techId) return null

    // This calculates the amount of em we need to move to the next circle
    const sumEm = 1.2 * stepIndex
    return (
      <>
        <ArrowButton icon={"arrLeft15"} onClick={() => previousStep()} />
        <StepsList>
          <CircleAround
            style={{
              transform: `translateX(calc(-0.1em + ${sumEm}em))`
            }}
          />

          {steps.map((step, index) => {
            const answered =
              step.type === "metric-question"
                ? // if is a question, check if was answered
                  !!currentTechnology.viewerAnswers.find(va => va.question.id === step.question.id)
                : // otherwise, just mark filled if the index is bigger
                  stepIndex > index

            const isCurrentStep = index === stepIndex

            return (
              <Circle
                onClick={() => selectStep(index)}
                key={`${step.type}_${index}`}
                filled={answered}
                style={{ margin: "0 2px" }}
              />
            )
          })}
        </StepsList>
        <ArrowButton icon={"arrRight15"} onClick={() => nextStep()} />
      </>
    )
  }

  return (
    <StyledModal
      isOpen={surveyState.isModalOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onBackgroundClick={() => surveyState.toggleModal()}
      onEscapeKeydown={() => surveyState.toggleModal()}
      opacity={opacity}
      backgroundProps={{
        opacity,
        color: "#F7F7FA"
      }}
    >
      <OuterTopContainer>
        <ModalHeader
          style={{
            marginLeft: 12
          }}
        >
          {boardIntersection({ board, technologies: survey.technologies }).length - answeredCount}{" "}
          technologies left
        </ModalHeader>
        <CloseIcon name="x25" size={25} onClick={() => surveyState.toggleModal()} />
      </OuterTopContainer>
      <Flippy
        isFlipped={surveyState.isModalFlipped}
        flipDirection="horizontal" // horizontal or vertical
        style={inlineStyles.flippyContainer}
      >
        <FrontSide style={inlineStyles.flippyCard}>
          <ModalWrapper>
            <FrontContentWrapper>
              <Element
                name="scrollElement"
                id="scrollElementId"
                style={{
                  height: "100%",
                  overflow: "auto"
                }}
              >
                <Card.Image
                  fullWidth
                  label={currentTechnology.name}
                  height={cardImageHeight}
                  secondaryLabel={TECHNOLOGY_ITEM_LABEL}
                  isImageLoading={isImageLoading}
                  style={{
                    borderRadius: 0
                  }}
                  noFilter={isImageLoading}
                  imageComponent={
                    <CloudImage
                      imageId={currentTechnology.imageId}
                      onImageLoaded={url => {
                        setIsImageLoading(false)
                      }}
                    />
                  }
                />
                <SurveyTechnology
                  description={
                    currentTechnology.description && currentTechnology.description.longText
                  }
                  summary={currentTechnology.description && currentTechnology.description.shortText}
                />
              </Element>
            </FrontContentWrapper>
            <ButtonWrapper>
              <ButtonKey
                onClick={() => startSurvey()}
                onKeyPress={!surveyState.started && (() => startSurvey())}
                keyCode={KeyCodeMap.Enter}
                minWidth={250}
                big={!isDesktop}
                kind={
                  isAllAnswersComplete ? "primary" : surveyState.started ? "neutral" : "default"
                }
                disabled={surveyState.started}
                style={{ width: !isDesktop ? "100%" : 0 }}
                buttonLabel={
                  isAllAnswersComplete
                    ? "completed - start again?"
                    : !surveyState.started
                    ? "answer"
                    : "answering"
                }
                keyPressLabel="enter"
              />
            </ButtonWrapper>
          </ModalWrapper>
        </FrontSide>
        <BackSide style={inlineStyles.flippyCard}>
          <ModalWrapper>
            <StyledListItem
              label={currentTechnology.name}
              secondaryLabel={TECHNOLOGY_ITEM_LABEL}
              avatar={
                <CloudImage
                  imageId={currentTechnology.imageId}
                  width={60}
                  height={60}
                  crop="fill"
                  gravity="center"
                  style={{ borderRadius: 3 }}
                />
              }
              renderAction={isDesktop && renderSteps()}
            />

            {!isDesktop && <StepsWrapper>{renderSteps()}</StepsWrapper>}
            <BackContentWrapper>
              {transitions.map(({ item, props, key }) => {
                return (
                  <AnimatedDiv style={props} key={key}>
                    {steps[stepIndex].type === "metric-question" && (
                      <SurveyMetricQuestion
                        metricName={steps[stepIndex].metric.name}
                        question={steps[stepIndex].question}
                        viewerAnswers={currentTechnology.viewerAnswers}
                        submitAnswer={submitAnswer}
                      />
                    )}
                    {steps[stepIndex].type === "finished" && (
                      <SurveyFinished onDone={nextTechnology} />
                    )}
                  </AnimatedDiv>
                )
              })}
            </BackContentWrapper>
          </ModalWrapper>
        </BackSide>
      </Flippy>
      <OuterButtonContainer>
        {!surveyState.isModalFlipped && !surveyState.started && (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <ArrowButton icon={"arrLeft15"} onClick={() => previousTechnology()} />
            <TechnologiesCount>
              Card <span style={{ fontWeight: "bold" }}>{surveyState.currentTechnologyNumber}</span>{" "}
              of <span style={{ fontWeight: "bold" }}>{technologiesCount}</span>
            </TechnologiesCount>
            <ArrowButton icon={"arrRight15"} onClick={() => nextTechnology()} />
          </div>
        )}
        {!surveyState.isModalFlipped && surveyState.started && (
          <Button minWidth={250} big onClick={() => surveyState.flipModal()}>
            back to answers
          </Button>
        )}
        {surveyState.isModalFlipped && surveyState.started && (
          <Button minWidth={250} big onClick={() => surveyState.flipModal()}>
            learn more
          </Button>
        )}
      </OuterButtonContainer>
    </StyledModal>
  )
})

export default withRouter(SurveyModal)

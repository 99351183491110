import * as React from "react"
import * as Sentry from "@sentry/browser"
import { Panel } from "@envisioning/design-library"
import { Container } from "react-grid-system"
import { useCreateSurveyMutation, CreateSurveyDocument } from "~/graphql"
import { PanelWrapper } from "./MetricForm"
import { PanelCollapsable } from "../PanelCollapsable"
import SurveyForm from "./SurveyForm"
import { toast } from "react-toastify"
interface Props {
  onSuccess: (message: string) => void
}

function CreateSurveyForm({ onSuccess }: Props) {
  const [createSurvey] = useCreateSurveyMutation(CreateSurveyDocument)

  async function submitForm(values: any) {
    try {
      await createSurvey({
        variables: {
          input: values
        }
      })
      onSuccess("Created successfully")
    } catch (error) {
      toast.error("There's something wrong")
      Sentry.configureScope(scope => {
        scope.setTag("page_locale", "create-survey")
      })
      Sentry.captureException(error)
      console.log(error)
    }
  }

  return (
    <Container>
      <Panel style={{ maxWidth: 1000, margin: "auto" }}>
        <Panel.Header>create survey</Panel.Header>
        <Panel.Body>
          <PanelWrapper>
            <PanelCollapsable startCollapsed={true} title="Survey">
              <SurveyForm submitForm={submitForm} mode={"create"} />
            </PanelCollapsable>
          </PanelWrapper>
        </Panel.Body>
      </Panel>
    </Container>
  )
}

export default CreateSurveyForm

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = closestAbove;

/**
 * Returns the first item in list that is greater or equal to the given value.
 * @param {Array} list a sorted array of items
 * @param {*} value
 * @returns {*}
 * @protected
 */
function closestAbove(list, value) {
  return list.reduce(function (last, next) {
    return value <= last ? last : next;
  }, undefined);
}
import { Metric, TechnologyFieldsFragment } from "../graphql"
const flatten = require("lodash/flatten")

export const imageUrl = (imageId: string) =>
  `https://res.cloudinary.com/envisioning/image/upload/v1/s3/images/${imageId}`

export const countQuestions = (metrics: Metric[]) => {
  return flatten(metrics.map(m => m.questions)).length
}

export const delay = (ms: number) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export const getScoreDisplayLabel = (score: number) => {
  if (score > 0) return `+${score.toString()}`
  return score
}

export const sortByAnsweredCount = (
  prev: TechnologyFieldsFragment,
  next: TechnologyFieldsFragment
) => prev.viewerAnswers.length - next.viewerAnswers.length

export const sortByShuffledOrder = ids => (a, b) => {
  const indexA = ids.indexOf(a.id)
  const indexB = ids.indexOf(b.id)
  if (indexA < indexB) return -1
  if (indexA > indexB) return 1
  return 0
}

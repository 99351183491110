import * as React from "react"
import * as Sentry from "@sentry/browser"
import { styled, AppProvider, DefaultTheme, Button, Text } from "@envisioning/design-library"
import { AppGlobalStyles } from "./styles"
import GlobalStyles from "@ev/platform/lib/modules/layout/globalStyles"

const isProd = process.env.NODE_ENV === "production"

if (isProd) {
  Sentry.init({
    dsn: "https://9a834697977a4bd59abed20e984d6b9f@sentry.io/1449053"
  })
}

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
  background-color: ${props => props.theme.colors.secondaryBg};
`

const TextWrapper = styled.div`
  max-width: 400px;
`

export class SentryErrorBoundary extends React.Component<{}, { error: any; eventId: any }> {
  constructor(props) {
    super(props)
    this.state = { error: null, eventId: null }
  }

  componentDidCatch(error, errorInfo) {
    if (isProd) {
      this.setState({ error })
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
        Sentry.captureException(error)
      })
    }
  }

  render() {
    if (this.state.error) {
      return (
        <AppProvider theme={DefaultTheme}>
          <>
            <GlobalStyles />
            <AppGlobalStyles />
            <ErrorWrapper>
              <TextWrapper>
                <Text style={{ fontWeight: "bold", fontSize: "2em" }}>
                  Sorry, some unexpected error happened
                </Text>
                <Text style={{ fontSize: "1.5em" }}>
                  Our team is working hard to fix the problem, you can give us some feedback about
                  what happened or try refresh the page.
                </Text>
              </TextWrapper>

              {isProd && (
                <Button
                  big
                  kind="primary-outline"
                  onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                >
                  Report Bug 🐛
                </Button>
              )}
            </ErrorWrapper>
          </>
        </AppProvider>
      )
    } else {
      return this.props.children
    }
  }
}

import * as React from "react"

export default function useKeyPress(targetKey, cb, deps) {
  React.useEffect(() => {
    function downHandler({ keyCode }) {
      if (keyCode === targetKey) {
        cb()
      }
    }

    window.addEventListener("keydown", downHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, deps)
}

import { Card, css, Dropdown, Icon, ListItem, styled, TextInput } from "@envisioning/design-library"
import CardGrid from "@ev/platform/lib/modules/shared/components/CardGrid"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { Col, Container, Row } from "react-grid-system"
import LazyLoad, { forceCheck } from "react-lazyload"
import { sortByAnsweredCount, sortByShuffledOrder } from "~/lib"
import {
  CARD_COMPONENT_HEIGHT,
  CARD_OFFSET,
  LIST_ITEM_COMPONENT_HEIGHT,
  LIST_ITEM_OFFSET,
  TECHNOLOGY_ITEM_LABEL
} from "../../constants"
import {
  MetricAnswerFieldsFragment,
  TechnologyFieldsFragment,
  useGetSurveyQuery
} from "../../graphql"
import media from "../../media"
import { surveyState } from "../../store/survey.store"
import boardIntersection from "../../utils/boardIntersection"
import CloudImage from "../common/CloudImage"
import { WithAuthComponentProps } from "../common/withAuth"

interface SearchFilterBarProps {
  value?: string
  searchPlaceholder?: string
  onSearch?: (value: string) => void
  gridMode: boolean
  setGridMode: (val: boolean) => void
  onFilterChange: (val: string) => void
  filter: string
}

export const EditButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 20;
  opacity: var(--edit-button-opacity);
  visibility: var(--edit-button-visibility);
  transition: opacity 250ms ease;
  &:hover {
    cursor: pointer;
  }
`

export const CarouselContainer = styled.div`
  position: relative;
  height: auto;
  width: auto;
  background-color: white;
  --edit-button-opacity: 0;
  --edit-button-visibility: "hidden";
  &:hover {
    --edit-button-opacity: 1;
    --edit-button-visibility: "visible";
  }
`

export const Circle = styled.div<{ filled?: boolean }>`
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  transition: background-color 150ms ease-in-out;
  background-color: ${({ filled, theme }) =>
    filled ? theme.colors.goColor : theme.colors.secondaryColor};
  &:hover {
    cursor: pointer;
  }
`

const DropdownStyled = styled(Dropdown)`
  height: 25px;
  font-size: 12px;
  border-radius: 5px;
  width: unset;
  min-width: 160px;
`

const TextInputStyled = styled(TextInput)`
  height: 25px;
  font-size: 12px;
  border-radius: 15px;
  padding: unset;
  padding-left: 60px;

  ${media.query.mobile`
    padding: 14px 0px 14px 65px;
  `}
`

const SearchBarWrapper = styled.div`
  margin-bottom: 10px;
  height: 25px;
  display: flex;
  display: flex;
  align-items: center;

  ${media.query.desktop`
    margin-bottom: 2.5rem;
  `}
`
const ListItemStyled = styled(ListItem)<{ isDone?: boolean }>`
  border-right: ${({ isDone }) => isDone && "2.5px solid"};
  border-right-color: ${({ isDone, theme }) => isDone && theme.colors.goColor};
  transition: background-color 0.3s ease;
  margin-bottom: 6px;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.secondaryBg};
  }
`

const TechnologyList = styled.div`
  ${media.query.mobile`
    margin-bottom: 60px;
  `}
`

const TechnologyCountWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  background-color: ${props => props.theme.colors.secondaryBg};
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9;

  width: 100%;
  height: 40px;
  bottom: 60px;

  ${media.query.desktop`
    width: calc(100% - 100px);
    height: 60px;
    bottom: 0;
  `}
`

const CardStyled = styled(Card)<{ isDone?: boolean }>`
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
  transition: bottom 0.2s, box-shadow 0.2s;
  ${props =>
    props.isDone &&
    css`
      border-bottom: 4.5px solid;
      border-bottom-color: ${props => props.theme.colors.goColor};
    `}

  &:hover {
    cursor: pointer;
    bottom: 5px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.3);
  }
`

const CountLabel = styled.div`
  color: ${props => props.theme.colors.mainColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 10px;
  text-transform: uppercase;
  padding: 0px 5px;
`

const AnsweredCountLabel = styled(CountLabel)`
  color: ${props => props.theme.colors.goColor};
`

const DisplayModeIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

const Filters = {
  SHOW_ALL: "SHOW ALL",
  SHOW_ANSWERED: "SHOW ANSWERED",
  SHOW_UNANSWERED: "SHOW UNANSWERED"
}

const SearchControlsWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`

const SearchFilterBar = (props: SearchFilterBarProps) => {
  const isDesktop = media.isDesktop()
  return (
    <SearchBarWrapper>
      <TextInputStyled
        placeholder={props.searchPlaceholder}
        addonLeft={<Icon name="magnifier15" size={12} color="secondaryColor" />}
        onChange={props.onSearch}
        value={props.value}
        noValidation
      />

      {isDesktop && (
        <SearchControlsWrapper>
          <DropdownStyled
            height={25}
            onChange={props.onFilterChange}
            defaultValue={props.filter}
            inputValue={props.filter}
          >
            <Dropdown.Option value={Filters.SHOW_ALL}>SHOW ALL</Dropdown.Option>
            <Dropdown.Option value={Filters.SHOW_ANSWERED}>SHOW ANSWERED</Dropdown.Option>
            <Dropdown.Option value={Filters.SHOW_UNANSWERED}>SHOW UNANSWERED</Dropdown.Option>
          </DropdownStyled>
          <DisplayModeIcon
            name="displayCards21"
            color={props.gridMode ? "goColor" : "mainColor"}
            onClick={() => props.setGridMode(true)}
            size={21}
            style={{ marginLeft: 20 }}
          />

          <DisplayModeIcon
            name="displayList21"
            color={!props.gridMode ? "goColor" : "mainColor"}
            onClick={() => props.setGridMode(false)}
            size={21}
          />
        </SearchControlsWrapper>
      )}
    </SearchBarWrapper>
  )
}

const SurveyList = observer((props: WithAuthComponentProps) => {
  const surveyId = props.match.params.surveyId
  const filterByBoard = props.search.filterByBoard

  const {
    data: { survey, board }
  } = useGetSurveyQuery({
    variables: {
      id: surveyId,
      boardId: filterByBoard ? filterByBoard : ""
    }
  })
  // State
  const isDesktop = media.isDesktop()

  const [searchText, setSearchText] = React.useState("")
  const [gridMode, setGridMode] = React.useState(isDesktop)
  const [filter, setFilter] = React.useState<string>(Filters.SHOW_ALL)

  const filteredTechnologies: TechnologyFieldsFragment[] = React.useMemo(() => {
    const searchResults: TechnologyFieldsFragment[] = boardIntersection({
      board: board,
      technologies: survey.technologies
    }).filter(t => t.name.toLowerCase().includes(searchText.toLowerCase()))

    switch (filter) {
      case Filters.SHOW_ANSWERED:
        return searchResults
          .filter(t => t.viewerAnswers.length === survey.questionsCount)
          .sort(sortByShuffledOrder(surveyState.technologyIds))
      case Filters.SHOW_UNANSWERED:
        return searchResults
          .filter(t => t.viewerAnswers.length !== survey.questionsCount)
          .sort(sortByShuffledOrder(surveyState.technologyIds))
      default:
        const results = searchResults
          .sort(sortByShuffledOrder(surveyState.technologyIds))
          .sort(sortByAnsweredCount)
        return results
    }
  }, [searchText, survey, filter, board])

  const answeredCount = React.useMemo(() => {
    return boardIntersection({ board, technologies: survey.technologies }).filter(
      t => t.viewerAnswers.length === survey.questionsCount
    ).length
  }, [survey, board])

  // Automatically sets the gridMode to true if is desktop
  // And change to list if is mobile
  React.useEffect(() => {
    setGridMode(isDesktop)
  }, [isDesktop])

  // Fix the lazyload on filters and state changes
  React.useEffect(() => {
    forceCheck()
  }, [filter, isDesktop, survey, filteredTechnologies])

  // Actions
  function openModal(technologyId: string) {
    surveyState.selectTechnology(technologyId)
    surveyState.toggleModal()
  }

  function isDoneCheck(answers: MetricAnswerFieldsFragment[]) {
    return answers.length === survey.questionsCount
  }

  const renderList = () => (
    <TechnologyList>
      {filteredTechnologies.map(tech => (
        <LazyLoad key={tech.id} height={LIST_ITEM_COMPONENT_HEIGHT} offset={LIST_ITEM_OFFSET}>
          <ListItemStyled
            label={tech.name}
            secondaryLabel={TECHNOLOGY_ITEM_LABEL}
            onClick={() => openModal(tech.id)}
            isDone={isDoneCheck(tech.viewerAnswers)}
            avatar={
              <CloudImage
                imageId={tech.imageId}
                width={60}
                height={60}
                crop="fill"
                gravity="center"
              />
            }
          />
        </LazyLoad>
      ))}
    </TechnologyList>
  )

  const renderGrid = () => (
    <CardGrid style={{ justifyContent: "flex-start" }}>
      {filteredTechnologies.map(tech => (
        <LazyLoad key={tech.id} height={CARD_COMPONENT_HEIGHT} offset={CARD_OFFSET}>
          <CardStyled
            label={tech.name}
            secondaryLabel={TECHNOLOGY_ITEM_LABEL}
            imageComponent={<CloudImage imageId={tech.imageId} />}
            description={tech.description.shortText}
            isDone={isDoneCheck(tech.viewerAnswers)}
            onClick={() => {
              surveyState.selectTechnology(tech.id)
              surveyState.toggleModal()
            }}
          />
        </LazyLoad>
      ))}
    </CardGrid>
  )

  return (
    <Container fluid>
      <Row nogutter>
        <Col sm={12} md={12} lg={12}>
          <SearchFilterBar
            onSearch={value => setSearchText(value)}
            searchPlaceholder={"Search"}
            value={searchText}
            gridMode={gridMode}
            setGridMode={setGridMode}
            onFilterChange={val => setFilter(val)}
            filter={filter}
          />

          {gridMode ? renderGrid() : renderList()}
        </Col>
        <TechnologyCountWrapper>
          <CountLabel>
            {boardIntersection({ board, technologies: survey.technologies }).length} Technologies
          </CountLabel>{" "}
          /<AnsweredCountLabel>{answeredCount} Answered</AnsweredCountLabel>
        </TechnologyCountWrapper>
      </Row>
    </Container>
  )
})

export default SurveyList

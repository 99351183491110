import { KeyCodeMap } from "./types"

export const CONTENT_BODY_ID = "content-body"
export const CONTENT_BODY_ID_SELECTOR = "#content-body"
export const LIST_ITEM_COMPONENT_HEIGHT = 81
export const CARD_COMPONENT_HEIGHT = 312

// This will make the LazyLoad component loads 3
// items in advance before it's in the viewport
export const LIST_ITEM_OFFSET = LIST_ITEM_COMPONENT_HEIGHT * 3
export const CARD_OFFSET = CARD_COMPONENT_HEIGHT

// Keys for answering questions
export const SCORE_KEYS = [KeyCodeMap.A, KeyCodeMap.S, KeyCodeMap.D, KeyCodeMap.F, KeyCodeMap.G]
export const TECHNOLOGY_ITEM_LABEL = "Technology"

// WORKGROUP
export const CARD_WORKGROUP_LABEL = "Organization"

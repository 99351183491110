import * as React from "react"
import { styled, Panel, Icon } from "@envisioning/design-library"

interface Props {
  title?: string
  startCollapsed?: boolean
  children: any
}

const PanelStyled = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  margin-bottom: 0.5rem;
`
export const PanelCollapsable = ({ title, startCollapsed, children }: Props) => {
  const [collapsed, setCollapsed] = React.useState(startCollapsed)

  return (
    <PanelStyled>
      <Panel.Header
        renderAction={() => (
          <Icon name={collapsed ? "arrUp15" : "arrDown15"} size={20} color="goColor" />
        )}
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        {title}
      </Panel.Header>

      {collapsed && <Panel.Body>{children}</Panel.Body>}
    </PanelStyled>
  )
}

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var React = require("react");
var responsive_1 = require("../../../util/responsive");
var components_1 = require("../components");
exports.ResponsiveGridLayout = design_library_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  min-height: 100%;\n  grid-template-columns: 1fr;\n  grid-template-rows: 60px 1fr 60px;\n  grid-template-areas:\n    \"header\"\n    \"content\"\n    \"navbar\";\n\n  ", "\n"], ["\n  display: grid;\n  min-height: 100%;\n  grid-template-columns: 1fr;\n  grid-template-rows: 60px 1fr 60px;\n  grid-template-areas:\n    \"header\"\n    \"content\"\n    \"navbar\";\n\n  ",
    "\n"])), responsive_1.media.desktop(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    grid-template-columns: 100px 1fr;\n    grid-template-rows: 80px 1fr 50px;\n    grid-template-areas:\n      \"navbar header\"\n      \"navbar content\"\n      \"navbar footer\";\n  "], ["\n    grid-template-columns: 100px 1fr;\n    grid-template-rows: 80px 1fr 50px;\n    grid-template-areas:\n      \"navbar header\"\n      \"navbar content\"\n      \"navbar footer\";\n  "]))));
var RootLayout = function (_a) {
    var children = _a.children, appName = _a.appName, links = _a.links;
    return (React.createElement(exports.ResponsiveGridLayout, null,
        React.createElement(components_1.Navbar, { links: links, appName: appName }),
        children,
        React.createElement(components_1.Footer, null, "Envisioning 2.0")));
};
exports.default = RootLayout;
var templateObject_1, templateObject_2;

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var design_library_1 = require("@envisioning/design-library");
exports.DashboardFilterStyled = design_library_1.styled(design_library_1.Dropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 181px;\n  max-height: 102px;\n"], ["\n  width: 181px;\n  max-height: 102px;\n"])));
function DashboardFilter(props) {
    return React.createElement(exports.DashboardFilterStyled, __assign({}, props));
}
exports.DashboardFilterOption = design_library_1.styled(design_library_1.Dropdown.Option)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: center;\n"], ["\n  justify-content: center;\n"])));
exports.default = Object.assign(DashboardFilter, {
    Option: exports.DashboardFilterOption
});
var templateObject_1, templateObject_2;

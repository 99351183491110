import { styled } from "@envisioning/design-library"
import * as React from "react"
import media from "~/media"
import Markdown, { MetricQuestionRenderers } from "../common/Markdown"
import ScoreButton, { ScoreButtonStyled } from "./ScoreButton"
import { SCORE_KEYS } from "~/constants"
import ButtonWrapper from "./ButtonWrapper"
import { TechnologyFieldsFragment, MetricAnswer } from "~/graphql"
interface Props {
  metricName: string
  question: any
  submitAnswer: (optionId: string) => void
  viewerAnswers: MetricAnswer[]
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TextWrapper = styled.div`
  height: 100%;
  padding: 20px 20px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${media.query.desktop`
    padding: 30px 50px 0;
  `}
`

const MetricName = styled.div`
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.colors.goColor};
  margin-bottom: 3px;
`

const QuestionLabel = styled.div`
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: ${props => props.theme.colors.goColor};
  margin-bottom: 12px;

  ${media.query.desktop`
    font-size: 18px;
  `}
`

const ScoreButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 2px 6px 2px;

  ${media.query.desktop`
    margin: 0px 10px;
    width: unset;
  `}

  ${ScoreButtonStyled} {
    margin: 0 2px;
  }
`

const ScoreLabel = styled.div`
  color: ${props => props.theme.colors.secondaryColor};
  font-family: ${({ theme }) => theme.fonts.nunito};
  font-size: 9px;
  text-align: left;
  text-transform: uppercase;
`

const optionsExtentDescriptions = (options:Array<{ description: string }>)=>{
 const [min,max] = [options[0],options[options.length-1]]
 return {
   minLabel: min.description,
   maxLabel: max.description 
 }
}

export default function SurveyMetricQuestion({
  metricName,
  question,
  submitAnswer,
  viewerAnswers
}: Props) {
  const isDesktop = media.isDesktop()
  const {minLabel,maxLabel}  = optionsExtentDescriptions(question.options)
  return (
    <Wrapper>
      <TextWrapper>
        <MetricName>{metricName}</MetricName>
        <QuestionLabel>{question.label}</QuestionLabel>
        <Markdown markdown={question.description} renderers={MetricQuestionRenderers} />
      </TextWrapper>
      <ButtonWrapper>
        {(isDesktop && minLabel) && <ScoreLabel>{minLabel}</ScoreLabel>}

        <ScoreButtonsWrapper>
          {question.options.map((option, index) => {
            return (
              <ScoreButton
                key={option.id}
                submitAnswer={submitAnswer}
                option={option}
                keyCode={SCORE_KEYS[index]}
              />
            )
          })}
        </ScoreButtonsWrapper>

        {(isDesktop && maxLabel) && <ScoreLabel>{maxLabel}</ScoreLabel>}
      </ButtonWrapper>
    </Wrapper>
  )
}

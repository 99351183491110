import * as React from "react"
import { styled, Text, Button } from "@envisioning/design-library"
import media from "~/media"
import ButtonKey from "../common/ButtonKey"
import { KeyCodeMap, KeyCodeMapReverse } from "~/types"
import ButtonWrapper from "./ButtonWrapper"

interface Props {
  onDone: () => void
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.goColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 30px;
  font-weight: 200;
`

export default function SurveyFinished({ onDone }: Props) {
  const isDesktop = media.isDesktop()
  return (
    <Wrapper>
      <TextWrapper>Thank you!</TextWrapper>
      <ButtonWrapper>
        <ButtonKey
          minWidth={200}
          onClick={onDone}
          onKeyPress={onDone}
          keyCode={KeyCodeMap.Enter}
          buttonLabel="Done"
          keyPressLabel={KeyCodeMapReverse[KeyCodeMap.Enter]}
          fullWidth={!isDesktop}
          big={!isDesktop}
          style={{
            margin: "0px 2px 6px 2px"
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

import * as React from "react"
import { RouteComponentProps } from "react-router-dom"
import { Params } from "./withAuth"
import { styled, Icon } from "@envisioning/design-library"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${props => props.theme.colors.sidebarColor};
`

const MainText = styled.div`
  color: ${props => props.theme.colors.secondaryBg};
  font-size: 16px;
  max-width: 35rem;
  text-align: center;
  line-height: 22px;
`

export default function NotFoundScreen() {
  return (
    <Wrapper>
      <Icon name="x25" size={35} />
      {/* TODO: Implement not found */}
      <MainText>
        This survey url seems to be invalid. <br />
        Please talk to an organizer.
      </MainText>
    </Wrapper>
  )
}

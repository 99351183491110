import * as React from "react"
import CreateMetricForm from "../survey/forms/CreateMetricForm"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"

export const MetricAdminCreate = (props: RouteComponentProps) => {
  return (
    <>
      <CreateMetricForm
        onSuccess={message => {
          props.history.push(`/admin/metrics`)
          toast.success(message)
        }}
      />
    </>
  )
}

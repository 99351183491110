import * as React from "react"
import { Button, Heading, ListItem, Loader } from "@envisioning/design-library"
import { Container } from "react-grid-system"
import { RouteComponentProps } from "react-router-dom"
import { useGetAllMetricsQuery } from "~/graphql"

export function MetricAdminListing(props: RouteComponentProps<{}>) {
  const { data, loading, error } = useGetAllMetricsQuery({ fetchPolicy: "network-only" })

  if (error) {
    return <div>Error on metrics list</div>
  }

  return (
    <Container>
      <Heading as="h3">Available Metrics</Heading>

      <Button
        onClick={() => {
          props.history.push(`/admin/create-metric`)
        }}
        style={{ marginBottom: 20, marginRight: 12 }}
      >
        create new metric
      </Button>
      <Button
        onClick={() => {
          props.history.push(`/admin`)
        }}
        style={{ marginBottom: 20 }}
      >
        survey list
      </Button>
      {loading ? (
        <Loader type={"ball-beat"} style={{ width: "100%", marginTop: 30 }} />
      ) : (
        data.metrics.map((metric, index) => (
          <ListItem
            key={index}
            label={metric.name}
            secondaryLabel=""
            onClick={() => {
              props.history.push(`/admin/metrics/${metric.id}`)
            }}
            style={{ cursor: "pointer" }}
            data-cy-metric-list
          />
        ))
      )}
    </Container>
  )
}

import { action, observable } from "mobx"
import { apiClient } from "../utils/api-client"

const AUTH_STRATEGY = "email_only"

class AuthStore {
  @observable isLoading: boolean = false

  @action
  postRegister = async (email: string, name: string) => {
    try {
      this.isLoading = true
      const payload = { name }
      const res = await apiClient.post(
        `/register?strategy=${AUTH_STRATEGY}&email=${email}`,
        payload
      )
      return {
        verifyCode: res.data.verifyCode
      }
    } catch (err) {
      throw err
    } finally {
      this.isLoading = false
    }
  }

  @action postVerifyEmail = async (verifyCode: string) => {
    const res = await apiClient.post(`/register/verify?verifyCode=${verifyCode}`)
    return res
  }

  @action postResendConfirmation = async (verifyCode: string) => {
    const res = await apiClient.post(`/register/resend-confirmation?verifyCode=${verifyCode}`)
    return res
  }

  @action postConfirmEmail = async (verifyToken: string) => {
    const res = await apiClient.post(`/register/confirm?token=${verifyToken}`)
    return res
  }
}

export const authStore = new AuthStore()

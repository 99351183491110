"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = debounce;

/**
 * Delay execution of func until wait milliseconds have passed since the last invocation of the returned debounced function.
 * @param {function} func the function to execute
 * @param {number} wait waiting time in milliseconds
 * @param {boolean=false} immediate if true execute func at the beginning of the wait period
 * @returns {function()} debounced function
 * @protected
 */
function debounce(func, wait, immediate) {
  var _this = this,
      _arguments = arguments;

  var timeout = null;

  var debounced = function debounced() {
    var context = _this;
    var args = _arguments;
    var callNow = immediate && !timeout;

    if (timeout) {
      clearTimeout(timeout);
    }

    var later = function later() {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };

  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
}
import { Card, Heading, Panel, styled } from "@envisioning/design-library"
import * as React from "react"
import { Col, Container, Row } from "react-grid-system"
import { CARD_WORKGROUP_LABEL } from "~/constants"
import { getScoreDisplayLabel } from "~/lib"
import media from "~/media"
import { apolloCache } from "~/utils/apollo-cache"
import CloudImage from "../common/CloudImage"
import Markdown from "../common/Markdown"
import { WithAuthComponentProps } from "../common/withAuth"
const first = require("lodash/first")

const CardWrapper = styled.div`
  position: relative;
  margin: auto;

  ${media.query.desktop`
    width: 25rem;
    position: fixed;
  `}
`

const StyledPanelBody = styled(Panel.Body)`
  margin-bottom: 1.5rem;
  padding: 2.5rem;

  ${media.query.desktop`
    max-width: 65rem;
    padding: 4rem;
  `}
`

const StyledCol = styled(Col)`
  max-width: 280px !important;
`

const MetricList = styled.ul`
  list-style: none;
  padding: 0;
`

const MetricListItem = styled.li`
  display: flex;
  margin-bottom: 10px;
`

const MetricLabel = styled.div`
  color: ${props => props.theme.colors.mainColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4rem;
  margin-bottom: -0.8rem;
`

const MetricDescription = styled.div`
  color: ${props => props.theme.colors.mainColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 1.4rem;
  line-height: 1.4rem;
`

const MetricScore = styled.div`
  background-color: ${props => props.theme.colors.goColor};
  color: ${props => props.theme.colors.white};
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 150ms ease-in-out;
`

function SurveyMetric(props: WithAuthComponentProps) {
  const surveyId = props.match.params.surveyId
  const filterByBoard = props.search.filterByBoard
  const { survey } = apolloCache.getSurvey(surveyId, filterByBoard ? filterByBoard : "")
  const metric = first(survey.metrics)
  const isDesktop = media.isDesktop()

  // Configuration from db
  const extraCardProps = survey.labelsOnImageEnabled
    ? {
        label: survey.workgroupName,
        secondaryLabel: CARD_WORKGROUP_LABEL,
        forceDarkLabel: survey.darkLabelsOnImageEnabled
      }
    : null

  return (
    <Container fluid style={{ paddingTop: isDesktop ? 40 : 0 }}>
      <Row nogutter>
        {isDesktop && (
          <StyledCol xl={3} lg={4}>
            <CardWrapper>
              <Card.Image
                {...extraCardProps}
                imageComponent={
                  <CloudImage imageId={survey.imageId} gravity="center" crop="scale" />
                }
                height={300}
                // Margin bottom prevents background bug on safari
                style={{ marginBottom: 10 }}
                noFilter
              />
            </CardWrapper>
          </StyledCol>
        )}
        <Col xl={9} lg={8} md={12} sm={12}>
          <Panel>
            <StyledPanelBody>
              <Markdown markdown={survey.metricPageText} />

              <Heading as="h3">Metrics</Heading>
              <MetricList>
                {survey.metrics.map(metric => (
                  <MetricListItem key={metric.id}>
                    <div style={{ marginTop: 5 }}>
                      <MetricLabel>{metric.name.toUpperCase()}</MetricLabel>
                      <MetricDescription>
                        <Markdown markdown={metric.description} />
                      </MetricDescription>
                    </div>
                  </MetricListItem>
                ))}
              </MetricList>

              <Heading as="h3">Ratings</Heading>

              <MetricList>
                {metric.resultLabels
                  .sort((prev, next) => next.score - prev.score)
                  .map(resultLabel => (
                    <MetricListItem key={`${resultLabel.__typename}_${resultLabel.score}`}>
                      <MetricScore>{getScoreDisplayLabel(resultLabel.score)}</MetricScore>
                      <div style={{ marginTop: 5, marginLeft: 10 }}>
                        <MetricLabel>{resultLabel.label.toUpperCase()}</MetricLabel>
                        <MetricDescription>
                          <Markdown markdown={resultLabel.description} />
                        </MetricDescription>
                      </div>
                    </MetricListItem>
                  ))}
              </MetricList>
            </StyledPanelBody>
          </Panel>
        </Col>
      </Row>
    </Container>
  )
}

export default SurveyMetric

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var responsive_1 = require("../../../../util/responsive");
exports.NavbarWrapper = design_library_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  display: grid;\n  grid-area: navbar;\n  grid-template-areas: \"navbar-menu\";\n  grid-template-rows: 1fr;\n  grid-template-columns: 100vw;\n  align-items: center;\n  width: 100%;\n  height: 6rem;\n  position: fixed;\n  bottom: 0;\n  z-index: 1000;\n\n  ", "\n"], ["\n  background-color: ", ";\n  display: grid;\n  grid-area: navbar;\n  grid-template-areas: \"navbar-menu\";\n  grid-template-rows: 1fr;\n  grid-template-columns: 100vw;\n  align-items: center;\n  width: 100%;\n  height: 6rem;\n  position: fixed;\n  bottom: 0;\n  z-index: 1000;\n\n  ",
    "\n"])), function (props) { return props.theme.colors.sidebarColor; }, responsive_1.media.desktop(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    grid-template-areas: \"navbar-logo\" \"navbar-app\" \"navbar-menu\";\n    grid-template-rows: 80px 30px 1fr;\n    grid-template-columns: 1fr;\n    width: 100px;\n    height: 100%;\n  "], ["\n    grid-template-areas: \"navbar-logo\" \"navbar-app\" \"navbar-menu\";\n    grid-template-rows: 80px 30px 1fr;\n    grid-template-columns: 1fr;\n    width: 100px;\n    height: 100%;\n  "]))));
exports.NavbarLogoWrapper = design_library_1.styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), responsive_1.media.desktop(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    grid-area: navbar-logo;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "], ["\n    grid-area: navbar-logo;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "]))));
exports.NavbarLogo = design_library_1.styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), responsive_1.media.desktop(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 40px;\n    height: 40px;\n  "], ["\n    width: 40px;\n    height: 40px;\n  "]))));
exports.NavbarApp = design_library_1.styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), responsive_1.media.desktop(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    grid-area: navbar-app;\n    font-size: 10px;\n    color: #ffffff45;\n    font-weight: lighter;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "], ["\n    grid-area: navbar-app;\n    font-size: 10px;\n    color: #ffffff45;\n    font-weight: lighter;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "]))));
exports.NavbarItems = design_library_1.styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  grid-area: navbar-menu;\n  height: auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  flex-shrink: 0;\n  position: fixed;\n  width: 100%;\n  bottom: 0;\n\n  ", "\n"], ["\n  grid-area: navbar-menu;\n  height: auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  flex-shrink: 0;\n  position: fixed;\n  width: 100%;\n  bottom: 0;\n\n  ",
    "\n"])), responsive_1.media.desktop(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    flex-direction: column;\n    position: unset;\n    width: unset;\n    bottom: unset;\n  "], ["\n    flex-direction: column;\n    position: unset;\n    width: unset;\n    bottom: unset;\n  "]))));
exports.IconLink = design_library_1.styled(design_library_1.Icon)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin: 0 auto;\n  opacity: 0.4;\n"], ["\n  margin: 0 auto;\n  opacity: 0.4;\n"])));
exports.NavbarItem = design_library_1.styled(design_library_1.Link).attrs({
    activeClassName: "active"
})(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\ncolor: ", ";\nwidth: 100%;\n\nmax-width: 80px;\npadding: 12.5px;\nalign-items: center;\njustify-content: center;\ntext-align: center;\ntransition: all 0.25s ease-in-out;\nposition: relative;\n\n", " {\n  fill: ", ";\n}\n\n", "\n\n&.active {\n  ", " {\n    fill: ", ";\n    opacity: 1;\n  }\n\n  &:after {\n    width: 3px;\n    height: 100%;\n    top: 0;\n  }\n}\n\n&:hover {\n  ", " {\n    fill: ", ";\n    opacity: 1;\n  }\n  &:after {\n    width: 3px;\n    height: 100%;\n    top: 0;\n   }\n }\n}\n"], ["\ncolor: ", ";\nwidth: 100%;\n\nmax-width: 80px;\npadding: 12.5px;\nalign-items: center;\njustify-content: center;\ntext-align: center;\ntransition: all 0.25s ease-in-out;\nposition: relative;\n\n", " {\n  fill: ", ";\n}\n\n",
    "\n\n&.active {\n  ", " {\n    fill: ", ";\n    opacity: 1;\n  }\n\n  &:after {\n    width: 3px;\n    height: 100%;\n    top: 0;\n  }\n}\n\n&:hover {\n  ", " {\n    fill: ", ";\n    opacity: 1;\n  }\n  &:after {\n    width: 3px;\n    height: 100%;\n    top: 0;\n   }\n }\n}\n"])), function (props) { return props.theme.colors.disabledColor; }, exports.IconLink, function (props) { return props.theme.colors.secondaryBg; }, responsive_1.media.desktop(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  max-width: unset;\n  &:after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 25px;\n    transition: all 400ms ease-in-out;\n    background-color: ", ";\n    width: 3px;\n    height: 0px;\n  }\n"], ["\n  max-width: unset;\n  &:after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 25px;\n    transition: all 400ms ease-in-out;\n    background-color: ", ";\n    width: 3px;\n    height: 0px;\n  }\n"])), function (props) { return props.theme.colors.goColor; }), exports.IconLink, function (props) { return props.theme.colors.secondaryBg; }, exports.IconLink, function (props) { return props.theme.colors.secondaryBg; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;

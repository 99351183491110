import axios from "axios"

export const apiClient = axios.create({
  baseURL: process.env.API_ENDPOINT,
  withCredentials: true,
  // Not working on Chrome 85.0, keep it considering other browsers
  // For Chrome, meta referrer has been added to the HTML header
  headers: {
      "Referrer-Policy": "no-referrer-when-downgrade",
      //The Referer header is missing an R, due to an original misspelling in the spec
      "Referer-Policy": "no-referrer-when-downgrade"
    
  }
})

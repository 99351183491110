import * as React from "react"
import { ButtonKeyPress, ButtonKeyPressProps } from "@envisioning/design-library"
import { KeyCodeMap } from "../../types"

interface ButtonKeyProps extends ButtonKeyPressProps {
  keyCode: KeyCodeMap
  onKeyPress: () => void
}

export default function ButtonKey({ keyCode, onKeyPress, ...props }: ButtonKeyProps) {
  async function onKeyup(event) {
    if (event.keyCode === keyCode) {
      onKeyPress && onKeyPress()
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", onKeyup)
    return () => {
      window.removeEventListener("keydown", onKeyup)
    }
  })

  return <ButtonKeyPress onClick={onKeyPress} {...props} />
}

import * as React from "react"
import { Loader } from "@envisioning/design-library"
import { RouteComponentProps } from "react-router-dom"
import { useGetSurveyFormInfoQuery } from "~/graphql"
import EditSurveyForm from "../survey/forms/EditSurveyForm"
import { PanelWrapper } from "../survey/forms/MetricForm"
import { toast } from "react-toastify"

type Params = {
  surveyId: string
}

export const SurveyAdminEdit = (props: RouteComponentProps<Params>) => {
  const surveyId = props.match.params.surveyId

  const { data, loading } = useGetSurveyFormInfoQuery({
    variables: {
      id: surveyId
    }
  })

  if (loading) {
    return <Loader type={"ball-beat"} style={{ width: "100%", height: "100%", margin: "auto" }} />
  }

  const survey = data.survey

  return (
    <PanelWrapper>
      <EditSurveyForm
        survey={survey}
        onSuccess={message => {
          props.history.push(`/admin`)
          toast.success(message)
        }}
      />
    </PanelWrapper>
  )
}

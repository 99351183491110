import "@babel/polyfill"
import { AppProvider, DefaultTheme } from "@envisioning/design-library"
import ReactRouterNavLink from "@ev/platform/lib/modules/shared/components/ReactRouterNavLink"
import * as React from "react"
import { ApolloProvider } from "react-apollo"
import * as ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ModalProvider } from "styled-react-modal"
import App from "./App"
import { FadingBackground } from "./components/survey/SurveyModal"
import { SentryErrorBoundary } from "./error-tracking"
import { apolloClient } from "./utils/apollo-client"
const rootEl = document.getElementById("__magic")

ReactDOM.render(
  <SentryErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <AppProvider theme={DefaultTheme} linkComponent={ReactRouterNavLink}>
        <ModalProvider backgroundComponent={FadingBackground}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ModalProvider>
      </AppProvider>
    </ApolloProvider>
  </SentryErrorBoundary>,
  rootEl
)

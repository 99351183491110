import { Location } from "history"

export type SearchParamName = "filterByBoard"

export function useURLSearchParams(location: Location<any>) {
  const urlQueries = new URLSearchParams(location.search)

  return {
    searchParams: (name: SearchParamName) => urlQueries.get(name)
  }
}

import { SurveyRouteConfig } from "./router.config"

export type Breakpoints = {
  mobile: number
  tablet: number
  desktop: number
  wide: number
}

export type RouteConfig = SurveyRouteConfig & {
  component: React.ComponentType<any>
}

export enum KeyCodeMap {
  Enter = 13,
  Esc = 27,
  ArrowLeft = 37,
  ArrowRight = 39,
  A = 65,
  S = 83,
  D = 68,
  F = 70,
  G = 71
}

export const KeyCodeMapReverse = {
  13: "Enter",
  65: "A",
  83: "S",
  68: "D",
  70: "F",
  71: "G"
}

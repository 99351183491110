import { useViewerQuery } from "../../graphql"

export const AdminOnly = ({ children }) => {
  const { data, loading } = useViewerQuery()

  if (loading) return null
  if (!data.viewer) return null
  if (!data.viewer.isAdmin) return null

  return children
}

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var CardGrid = design_library_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));\n  grid-gap: 40px;\n  justify-content: center;\n  margin: 0 auto;\n"], ["\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));\n  grid-gap: 40px;\n  justify-content: center;\n  margin: 0 auto;\n"])));
exports.default = CardGrid;
var templateObject_1;

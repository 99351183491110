"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var design_library_1 = require("@envisioning/design-library");
var react_loaders_1 = require("react-loaders");
require("loaders.css/loaders.css");
var Wrapper = design_library_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
exports.LoaderStyled = design_library_1.styled(react_loaders_1.Loader)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  > div > div {\n    background-color: ", ";\n  }\n"], ["\n  > div > div {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.colors[props.color]; });
var LoadingView = function (props) { return (React.createElement(Wrapper, __assign({}, props, { "data-testid": "loading-view" }),
    React.createElement(exports.LoaderStyled, { type: props.type || "ball-beat", color: "secondaryColor" }),
    props.children)); };
exports.default = LoadingView;
var templateObject_1, templateObject_2;

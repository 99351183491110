import * as React from "react"
import * as Sentry from "@sentry/browser"
import { PureQueryOptions } from "apollo-client"
import { useUpdateMetricMutation, UpdateMetricDocument } from "~/graphql"
import MetricForm, { PanelWrapper } from "./MetricForm"
import { PanelCollapsable } from "../PanelCollapsable"
import { toast } from "react-toastify"
interface Props {
  metric?: any
  refetchQueries?: PureQueryOptions[]
  onSuccess: (message: string) => void
}

const EditMetricForm = ({ metric, refetchQueries, onSuccess }: Props) => {
  const [updateMetric] = useUpdateMetricMutation(UpdateMetricDocument)

  async function submitForm(values: any) {
    try {
      await updateMetric({
        variables: {
          input: values
        },
        refetchQueries
      })
      onSuccess("Edited successfully")
    } catch (error) {
      toast.error("There's something wrong")
      Sentry.configureScope(scope => {
        scope.setTag("page_locale", "edit-metric")
      })
      Sentry.captureException(error)
      console.log("error", error)
    }
  }

  return (
    <PanelWrapper>
      <PanelCollapsable startCollapsed={true} title={`Metric - ${metric.name}`}>
        <MetricForm metric={metric} key={metric.id} submitForm={submitForm} mode={"edit"} />
      </PanelCollapsable>
    </PanelWrapper>
  )
}

export default EditMetricForm

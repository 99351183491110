import { Heading, HeadingType, styled, Text } from "@envisioning/design-library"
import * as React from "react"
import * as ReactMarkdown from "react-markdown"
import media from "../../media"

type Renderers = {
  [nodeType: string]: React.ElementType<any>
}

interface Props {
  markdown: string
  renderers?: Renderers
}

const Heading1 = styled.h1`
  color: ${props => props.theme.colors.mainColor};
  font-size: 1.6rem;
`

const Heading3 = styled.h3`
  color: ${props => props.theme.colors.mainColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 1.1rem;
  font-weight: 900;
  line-height: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
`

const DefaultParagraph = styled(Text)`
  ${media.query.desktop`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`

function getDefaultParagraph(props) {
  return <DefaultParagraph {...props} />
}

const StyledParagraph = styled(Text)`
  color: ${props => props.theme.colors.summaryColor};
  font-size: 1.8rem;
  font-weight: 200;
  line-height: 2.4rem;

  ${media.query.desktop`
    font-size: 2.4rem;
    line-height: 3.2rem;
  `}
`

function getStyledParagraph(props) {
  return <StyledParagraph {...props} />
}

const DefaultAnchor = styled.a`
  color: ${props => props.theme.colors.goColor};
  text-decoration: none;
`

function getDefaultAnchor(props) {
  return <DefaultAnchor {...props} />
}

const StyledUnorderedList = styled.ul`
  margin: 0;
  padding-left: 2.2rem;
`

function getUnorderedList(props) {
  return <StyledUnorderedList {...props} />
}

const StyledListItem = styled.li`
  color: ${props => props.theme.colors.mainColor};
  font-family: ${props => props.theme.fonts.nunito};
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2rem;
`

function getListItem(props) {
  return <StyledListItem {...props} />
}

export const DefaultRenderers = {
  paragraph: getDefaultParagraph,
  list: getUnorderedList,
  listItem: getListItem,
  link: getDefaultAnchor,
  heading: ({ children, level }) => {
    const headingType = `h${level}` as HeadingType
    if (headingType === "h1") {
      return <Heading1>{children}</Heading1>
    } else if (headingType === "h3") {
      return <Heading3>{children}</Heading3>
    } else {
      return <Heading as={headingType}>{children}</Heading>
    }
  }
}

export const MetricQuestionRenderers = {
  ...DefaultRenderers,
  paragraph: getStyledParagraph
}

export default function Markdown({ markdown, renderers }: Props) {
  return <ReactMarkdown source={markdown} escapeHtml={false} renderers={renderers} />
}

Markdown.defaultProps = {
  markdown: "",
  renderers: DefaultRenderers
}

import { Button, Loader } from "@envisioning/design-library"
import Content from "@ev/platform/lib/modules/layout/components/Content/Content"
import Header from "@ev/platform/lib/modules/layout/components/Header/Header"
import Navbar from "@ev/platform/lib/modules/layout/components/Navbar/Navbar"
import { ResponsiveGridLayout } from "@ev/platform/lib/modules/layout/layouts/RootLayout"
import * as React from "react"
import { Route, RouteComponentProps, Switch, Redirect } from "react-router-dom"
import routerConfig from "~/router.config"
import { SurveyAdminListing } from "./SurveyAdminListing"
import { SurveyAdminCreate } from "./SurveyAdminCreate"
import { SurveyAdminEdit } from "./SurveyAdminEdit"
import { MetricAdminListing } from "./MetricAdminListing"
import { MetricAdminCreate } from "./MetricAdminCreate"
import { MetricAdminEdit } from "./MetricAdminEdit"
import { useViewerQuery, useLogoutMutation } from "~/graphql"
import { ToastContainer } from "react-toastify"
import LoadingView from "@ev/platform/lib/components/LoadingView"

export type Params = {
  surveyId: string
}

export const SurveyAdmin = (props: RouteComponentProps) => {
  const { data, loading } = useViewerQuery()
  const [logout] = useLogoutMutation()

  if (loading) {
    return <LoadingView type="line-scale" />
  }

  if (!data) {
    alert("You must be logged in to access this page")
    return <Redirect to="/" />
  }

  if (!data.viewer.isAdmin) {
    alert("You must be admin to access this page")
    return <Redirect to="/" />
  }

  return (
    <ResponsiveGridLayout>
      <Navbar links={[]} appName={"ADMIN"} />
      <Header title={"Survey Admin"}>
        <Header.User>
          <Button
            kind={"cancel"}
            onClick={async () => {
              await logout()
              props.history.push(`/`)
            }}
            style={{ minWidth: 80 }}
          >
            logout
          </Button>
        </Header.User>
      </Header>

      <Content>
        <Content.Body>
          <React.Suspense fallback={<Loader type="ball-beat" color="waitColor" />}>
            <Switch>
              <Route exact path={routerConfig.admin.path} component={SurveyAdminListing} />
              <Route
                exact
                path={routerConfig.adminSurveyCreate.path}
                render={props => <SurveyAdminCreate {...props} />}
              />
              <Route
                exact
                path={routerConfig.adminSurveyEdit.path}
                render={props => <SurveyAdminEdit {...props} />}
              />
              <Route exact path={routerConfig.adminMetrics.path} component={MetricAdminListing} />
              <Route
                exact
                path={routerConfig.adminMetricsCreate.path}
                render={props => <MetricAdminCreate {...props} />}
              />
              <Route
                exact
                path={routerConfig.adminMetricsEdit.path}
                render={props => <MetricAdminEdit {...props} />}
              />
            </Switch>
          </React.Suspense>
          <ToastContainer autoClose={1700} />
        </Content.Body>
      </Content>
    </ResponsiveGridLayout>
  )
}

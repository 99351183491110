"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var responsive_1 = require("../../../../util/responsive");
exports.HeaderWrapper = design_library_1.styled.header(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: header;\n  display: grid;\n  grid-template-areas: \"header-left header-context header-user\";\n  grid-template-columns: 60px 1fr 60px;\n  grid-template-rows: 60px;\n  width: 100%;\n  background-color: ", ";\n  position: fixed;\n  z-index: 999;\n\n  ", "\n\n  ", "\n"], ["\n  grid-area: header;\n  display: grid;\n  grid-template-areas: \"header-left header-context header-user\";\n  grid-template-columns: 60px 1fr 60px;\n  grid-template-rows: 60px;\n  width: 100%;\n  background-color: ", ";\n  position: fixed;\n  z-index: 999;\n\n  ",
    "\n\n  ",
    "\n"])), function (props) { return props.theme.colors.sidebarColor; }, responsive_1.media.tablet(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    grid-template-columns: 60px 1fr 120px;\n  "], ["\n    grid-template-columns: 60px 1fr 120px;\n  "]))), responsive_1.media.desktop(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    grid-template-columns: 50px 1fr 300px;\n    grid-template-rows: 80px;\n    width: 100%;\n    padding-left: 100px;\n    background-color: ", ";\n    right: 0;\n  "], ["\n    grid-template-columns: 50px 1fr 300px;\n    grid-template-rows: 80px;\n    width: 100%;\n    padding-left: 100px;\n    background-color: ", ";\n    right: 0;\n  "])), function (props) { return props.theme.colors.secondaryBg; }));
exports.HeaderTitle = design_library_1.styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.4rem;\n  font-weight: 300;\n  line-height: 1.8rem;\n\n  ", "\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.4rem;\n  font-weight: 300;\n  line-height: 1.8rem;\n\n  ",
    "\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.fonts.roboto; }, responsive_1.media.desktop(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n    font-size: 1.8rem;\n    line-height: 2.1rem;\n  "], ["\n    color: ", ";\n    font-size: 1.8rem;\n    line-height: 2.1rem;\n  "])), function (props) { return props.theme.colors.mainColor; }));
exports.HeaderSubtitle = design_library_1.styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1rem;\n  line-height: 1.4rem;\n  text-transform: uppercase;\n\n  ", "\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1rem;\n  line-height: 1.4rem;\n  text-transform: uppercase;\n\n  ",
    "\n"])), function (props) { return props.theme.colors.goColor; }, function (props) { return props.theme.fonts.nunito; }, responsive_1.media.desktop(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: bold;\n  "], ["\n    font-weight: bold;\n  "]))));
exports.HeaderAction = design_library_1.styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  grid-area: header-action;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  grid-area: header-action;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
exports.HeaderContext = design_library_1.styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  grid-area: header-context;\n  display: flex;\n  font-family: ", ";\n  flex-direction: column;\n  justify-content: center;\n  color: ", ";\n  padding: 10px;\n  text-align: center;\n\n  ", "\n"], ["\n  grid-area: header-context;\n  display: flex;\n  font-family: ", ";\n  flex-direction: column;\n  justify-content: center;\n  color: ", ";\n  padding: 10px;\n  text-align: center;\n\n  ",
    "\n"])), function (props) { return props.theme.fonts.roboto; }, function (props) { return props.theme.colors.mainBg; }, responsive_1.media.desktop(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    text-align: left;\n    line-height: 20px;\n    color: ", ";\n    padding: 19px 0px;\n  "], ["\n    text-align: left;\n    line-height: 20px;\n    color: ", ";\n    padding: 19px 0px;\n  "])), function (props) { return props.theme.colors.goColor; }));
exports.HeaderLogoWrapper = design_library_1.styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  grid-area: header-left;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  grid-area: header-left;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
exports.HeaderLogo = design_library_1.styled.img(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n"], ["\n  width: 30px;\n  height: 30px;\n"
    // Refactoring
])));
// Refactoring
exports.HeaderSitemap = design_library_1.styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  grid-area: header-sitemap;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  grid-area: header-sitemap;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
exports.HeaderUser = design_library_1.styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  grid-area: header-user;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-right: 1.5rem;\n\n  ", "\n"], ["\n  grid-area: header-user;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-right: 1.5rem;\n\n  ",
    "\n"])), responsive_1.media.desktop(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    margin-right: 2.5rem;  \n  "], ["\n    margin-right: 2.5rem;  \n  "]))));
exports.EmailLabel = design_library_1.styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 300;\n  color: ", ";\n  text-align: right;\n  padding-bottom: 5px;\n"], ["\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 300;\n  color: ", ";\n  text-align: right;\n  padding-bottom: 5px;\n"])), function (props) { return props.theme.fonts.nunito; }, function (props) { return props.theme.colors.mainBg; });
exports.WorkgroupLabel = design_library_1.styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 9px;\n  font-weight: 600;\n  color: ", ";\n  text-transform: uppercase;\n  text-align: right;\n"], ["\n  font-family: ", ";\n  font-size: 9px;\n  font-weight: 600;\n  color: ", ";\n  text-transform: uppercase;\n  text-align: right;\n"])), function (props) { return props.theme.fonts.nunito; }, function (props) { return props.theme.colors.goColor; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
exports.breakpoints = {
    mobile: 576,
    tablet: 577,
    desktop: 992,
    wide: 1200
};
exports.media = Object.keys(exports.breakpoints).reduce(function (accumulator, label) {
    if (label === "mobile") {
        accumulator[label] = function (first) {
            var rest = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                rest[_i - 1] = arguments[_i];
            }
            return design_library_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      @media (max-width: ", "px) {\n        ", ";\n      }\n    "], ["\n      @media (max-width: ", "px) {\n        ", ";\n      }\n    "])), exports.breakpoints[label], design_library_1.css.apply(void 0, [first].concat(rest)));
        };
        return accumulator;
    }
    accumulator[label] = function (first) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        return design_library_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media (min-width: ", "px) {\n      ", ";\n    }\n  "], ["\n    @media (min-width: ", "px) {\n      ", ";\n    }\n  "])), exports.breakpoints[label], design_library_1.css.apply(void 0, [first].concat(rest)));
    };
    return accumulator;
}, {});
var templateObject_1, templateObject_2;

import * as React from "react"

const SvgLogo = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 495.6 100"
      enable-background="new 0 0 495.6 100"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M53.9,31.9H32.4c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h21.5c0.4,0,0.7-0.3,0.7-0.7v-2.6
			c0-0.4-0.3-0.7-0.7-0.7H36.1V51.8h16.8c0.4,0,0.7-0.3,0.7-0.7v-2.7c0-0.4-0.3-0.7-0.7-0.7H36.1V35.9h17.8c0.4,0,0.7-0.3,0.7-0.7
			v-2.6C54.6,32.2,54.2,31.9,53.9,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M101.2,31.9h-2.9c-0.4,0-0.7,0.3-0.7,0.7v27.6l-20.8-28c-0.1-0.2-0.3-0.3-0.6-0.3h-2.4
			c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7V39.8l20.9,28.1c0.1,0.2,0.3,0.3,0.6,0.3h2.4
			c0.4,0,0.7-0.3,0.7-0.7V32.6C101.9,32.2,101.6,31.9,101.2,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M151.8,31.9h-3.1c-0.3,0-0.5,0.2-0.6,0.4l-12.7,29.5l-12.7-29.5c-0.1-0.3-0.4-0.4-0.6-0.4h-3.2
			c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.2-0.1,0.4-0.1,0.6l15.1,34.9c0.1,0.3,0.4,0.4,0.6,0.4h2.8c0.3,0,0.5-0.2,0.6-0.4l15.1-34.9
			c0.1-0.2,0.1-0.5-0.1-0.6C152.3,32,152.1,31.9,151.8,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M172.6,31.9h-3.1c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7V32.6
			C173.3,32.2,173,31.9,172.6,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M212.3,50.2c-1.6-0.6-3.7-1.2-6.4-1.9l-1.3-0.3c-2.7-0.6-4.7-1.4-6.1-2.3c-1.2-0.8-1.8-2-1.8-3.8
			c0-2,0.7-3.6,2.2-4.7c1.5-1.2,3.7-1.7,6.5-1.7c1.9,0,3.7,0.3,5.3,1c1.6,0.6,3.2,1.7,4.8,3.1c0.2,0.1,0.4,0.2,0.6,0.2
			c0.2,0,0.4-0.2,0.5-0.4l1.3-2.4c0.1-0.3,0.1-0.6-0.1-0.8c-1.5-1.5-3.4-2.7-5.5-3.5c-2.1-0.8-4.4-1.2-6.8-1.2
			c-2.6,0-4.9,0.4-6.8,1.3c-2,0.9-3.5,2.1-4.6,3.7c-1.1,1.6-1.7,3.5-1.7,5.6c0,2.3,0.6,4.2,1.9,5.6c1.2,1.3,2.7,2.4,4.5,3
			c1.7,0.6,3.9,1.3,6.6,1.9c2,0.5,3.6,0.9,4.8,1.3c1.1,0.4,2,1,2.8,1.7c0.7,0.7,1,1.5,1,2.7c0,2-0.7,3.4-2.2,4.5
			c-1.5,1.1-3.7,1.7-6.4,1.7c-2.4,0-4.4-0.3-6.1-0.9c-1.7-0.6-3.4-1.7-5-3.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.2-0.5,0.4
			l-1.3,2.4c-0.1,0.3-0.1,0.6,0.1,0.8c1.7,1.6,3.7,2.8,5.9,3.6c2.2,0.8,4.7,1.2,7.5,1.2c2.6,0,4.8-0.4,6.8-1.3
			c2-0.9,3.5-2.1,4.6-3.6c1.1-1.6,1.7-3.4,1.7-5.4c0-2.1-0.6-3.9-1.8-5.2C215.4,51.8,214,50.8,212.3,50.2z"
          />
          <path
            fill="#FFFFFF"
            d="M240.4,31.9h-3.1c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7V32.6
			C241.1,32.2,240.8,31.9,240.4,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M285.1,33.7c-2.4-1.5-5.4-2.3-8.7-2.3c-3.3,0-6.2,0.8-8.6,2.3c-2.4,1.5-4.3,3.7-5.6,6.5
			c-1.3,2.8-1.9,6.1-1.9,9.8c0,3.7,0.7,7,1.9,9.8c1.3,2.8,3.2,5,5.7,6.5c2.4,1.5,5.3,2.3,8.6,2.3c3.3,0,6.2-0.8,8.7-2.3
			c2.4-1.5,4.3-3.7,5.7-6.5c1.3-2.8,1.9-6.1,1.9-9.8c0-3.7-0.7-7-1.9-9.8C289.5,37.4,287.6,35.2,285.1,33.7z M288.2,50
			c0,4.6-1,8.2-3.1,10.7c-2.1,2.5-4.9,3.7-8.6,3.7c-3.7,0-6.6-1.2-8.6-3.7c-2-2.5-3.1-6.1-3.1-10.7s1-8.2,3.1-10.7
			c2-2.5,4.9-3.7,8.6-3.7c3.8,0,6.6,1.2,8.6,3.7C287.1,41.8,288.2,45.4,288.2,50z"
          />
          <path
            fill="#FFFFFF"
            d="M339.9,31.9H337c-0.4,0-0.7,0.3-0.7,0.7v27.6l-20.8-28c-0.1-0.2-0.3-0.3-0.6-0.3h-2.4
			c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7V39.8L337,67.8c0.1,0.2,0.3,0.3,0.6,0.3h2.4
			c0.4,0,0.7-0.3,0.7-0.7V32.6C340.6,32.2,340.3,31.9,339.9,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M364.8,31.9h-3.1c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7V32.6
			C365.5,32.2,365.2,31.9,364.8,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M414,31.9h-2.9c-0.4,0-0.7,0.3-0.7,0.7v27.6l-20.8-28c-0.1-0.2-0.3-0.3-0.6-0.3h-2.4
			c-0.4,0-0.7,0.3-0.7,0.7v34.9c0,0.4,0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7V39.8l20.9,28.1c0.1,0.2,0.3,0.3,0.6,0.3h2.4
			c0.4,0,0.7-0.3,0.7-0.7V32.6C414.7,32.2,414.4,31.9,414,31.9z"
          />
          <path
            fill="#FFFFFF"
            d="M462.8,48.9h-12c-0.4,0-0.7,0.3-0.7,0.7v2.6c0,0.4,0.3,0.7,0.7,0.7h8.5v10.3c-1.1,0.4-2.4,0.7-3.7,1
			c-1.5,0.3-3.1,0.4-4.7,0.4c-4.1,0-7.2-1.2-9.3-3.7c-2.1-2.5-3.2-6.1-3.2-10.8c0-4.6,1.1-8.2,3.2-10.7c2.1-2.4,5.1-3.7,9-3.7
			c2,0,3.8,0.3,5.4,0.9c1.6,0.6,3.2,1.7,4.8,3.1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.2,0.5-0.4l1.3-2.4c0.1-0.3,0.1-0.6-0.1-0.8
			c-3.2-3.2-7.3-4.7-12.4-4.7c-3.4,0-6.4,0.7-8.9,2.2c-2.5,1.5-4.5,3.6-5.8,6.4c-1.3,2.8-2,6.1-2,9.9c0,3.8,0.7,7.1,2,9.9
			c1.4,2.8,3.3,4.9,5.9,6.4c2.6,1.5,5.6,2.2,9.1,2.2c2.3,0,4.6-0.2,6.8-0.7c2.3-0.5,4.1-1.1,5.4-1.8c0.2-0.1,0.4-0.4,0.4-0.6V49.6
			C463.5,49.2,463.2,48.9,462.8,48.9z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M13.7,100H4.4C2,100,0,98,0,95.6V4.4C0,2,2,0,4.4,0h9.3v4.4H4.4v91.2h9.3V100z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M489,100h-7.1v-4.4h7.1c1.2,0,2.2-1,2.2-2.2v-89h-9.3V0h9.3c2.4,0,4.4,2,4.4,4.4v89
				C495.6,97,492.6,100,489,100z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgLogo

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var React = require("react");
var DashboardFilter_1 = require("../../../../components/DashboardFilter");
var DashboardFilters = design_library_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  ", ":not(:first-child) {\n    border-left: 1px solid ", ";\n  }\n\n  ", ":last-child {\n    border-right: 1px solid ", ";\n  }\n"], ["\n  display: flex;\n\n  ", ":not(:first-child) {\n    border-left: 1px solid ", ";\n  }\n\n  ", ":last-child {\n    border-right: 1px solid ", ";\n  }\n"])), DashboardFilter_1.DashboardFilterStyled, function (props) { return props.theme.colors.borderColor; }, DashboardFilter_1.DashboardFilterStyled, function (props) { return props.theme.colors.borderColor; });
var TextInputStyled = design_library_1.styled(design_library_1.TextInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0;\n"], ["\n  border: 0;\n"])));
var SearchFilterBar = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(DashboardFilters, null, props.filters &&
        props.filters.map(function (filterProps, index) { return (React.createElement(DashboardFilter_1.default, { key: index, noBorder: true, icon: filterProps.icon, defaultValue: filterProps.defaultValue, onChange: function (value) { return filterProps.onFilter(value); }, label: filterProps.label }, filterProps.options.map(function (opt) { return (React.createElement(DashboardFilter_1.default.Option, { key: opt, value: opt }, opt)); }))); })),
    React.createElement(TextInputStyled, { placeholder: props.searchPlaceholder, addonLeft: React.createElement(design_library_1.Icon, { name: "magnifier25", size: 25, color: "secondaryColor" }), onChange: props.onSearch, noValidation: true }))); };
exports.default = SearchFilterBar;
var templateObject_1, templateObject_2;

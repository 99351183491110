import * as React from "react"
import { Loader } from "@envisioning/design-library"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import { useGetMetricByIdQuery } from "~/graphql"
import EditMetricForm from "../survey/forms/EditMetricForm"
import { PanelWrapper } from "../survey/forms/MetricForm"

type Params = {
  metricId: string
}

export const MetricAdminEdit = (props: RouteComponentProps<Params>) => {
  const metricId = props.match.params.metricId

  const { data, loading } = useGetMetricByIdQuery({
    variables: {
      id: metricId
    }
  })

  if (loading) {
    return <Loader type={"ball-beat"} style={{ width: "100%", height: "100%", margin: "auto" }} />
  }

  const metric = data.metric

  return (
    <PanelWrapper>
      <EditMetricForm
        metric={metric}
        onSuccess={message => {
          props.history.push(`/admin/metrics`)
          toast.success(message)
        }}
      />
    </PanelWrapper>
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Navbar_styles_1 = require("./Navbar.styles");
function NavbarLink(props) {
    return (React.createElement(Navbar_styles_1.NavbarItem, { exact: props.exact, url: props.to },
        React.createElement(Navbar_styles_1.IconLink, { name: props.icon, size: 25, color: "secondaryColor" })));
}
NavbarLink.defaultProps = {
    exact: true
};
exports.default = NavbarLink;

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var design_library_1 = require("@envisioning/design-library");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var responsive_1 = require("../../../../util/responsive");
var Content = design_library_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  display: grid;\n  grid-area: content;\n  grid-template-areas:\n    \"content-header\"\n    \"content-body\";\n  grid-template-columns: 1fr;\n  grid-template-rows: 35px 1fr;\n  scroll-behavior: smooth;\n  -webkit-overflow-scrolling: touch;\n\n  ", "\n"], ["\n  background-color: ", ";\n  display: grid;\n  grid-area: content;\n  grid-template-areas:\n    \"content-header\"\n    \"content-body\";\n  grid-template-columns: 1fr;\n  grid-template-rows: 35px 1fr;\n  scroll-behavior: smooth;\n  -webkit-overflow-scrolling: touch;\n\n  ",
    "\n"])), function (props) { return props.theme.colors.secondaryBg; }, responsive_1.media.desktop(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    grid-template-areas:\n      \"content-body\";\n    grid-template-rows: 31.5px 1fr;\n    grid-template-rows: 1fr;\n    overflow: hidden;\n  "], ["\n    grid-template-areas:\n      \"content-body\";\n    grid-template-rows: 31.5px 1fr;\n    grid-template-rows: 1fr;\n    overflow: hidden;\n  "]))));
exports.ContentHeader = design_library_1.styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: content-header;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  position: fixed;\n  width: 100%;\n  height: 31.5px;\n  padding: 0;\n  z-index: 999;\n\n  ", "\n"], ["\n  grid-area: content-header;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  position: fixed;\n  width: 100%;\n  height: 31.5px;\n  padding: 0;\n  z-index: 999;\n\n  ",
    "\n"])), function (props) { return props.theme.colors.borderColor; }, responsive_1.media.mobile(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: ", ";\n  "], ["\n    background-color: ", ";\n  "])), function (props) { return props.theme.colors.secondarySidebarColor; }));
exports.ContentHeaderActions = design_library_1.styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  overflow: hidden;\n  grid-area: content-header-actions;\n  align-items: center;\n  display: flex;\n  padding: 0px 20px;\n  height: 30px;\n  border-bottom: 1px solid ", ";\n"], ["\n  overflow: hidden;\n  grid-area: content-header-actions;\n  align-items: center;\n  display: flex;\n  padding: 0px 20px;\n  height: 30px;\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.colors.borderColor; });
exports.ContentBody = design_library_1.styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  grid-area: content-body;\n  background-color: ", ";\n\n  ", "\n"], ["\n  width: 100%;\n  grid-area: content-body;\n  background-color: ", ";\n\n  ",
    "\n"])), function (props) { return props.theme.colors.secondaryBg; }, responsive_1.media.desktop(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding: ", ";\n  "], ["\n    padding: ", ";\n  "])), function (props) { return (props.noPadding ? "0" : "0 3.5rem 3rem"); }));
var AppContentHeaderNavLink = design_library_1.styled(react_router_dom_1.NavLink).attrs({
    activeClassName: "active"
})(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  margin: 0 30px;\n  color: ", ";\n  transition: all 0.25s ease-in-out;\n\n  &.active {\n    color: ", ";\n\n    &:after {\n      width: calc(100% + 60px);\n      left: -30px;\n      right: -30px;\n    }\n  }\n\n  &:after {\n    content: \"\";\n    position: absolute;\n\n    top: 28px;\n    left: 50%;\n    background-color: ", ";\n    width: 0px;\n    transition: all 400ms ease-in-out;\n    height: 1px;\n    ", "\n  }\n"], ["\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  margin: 0 30px;\n  color: ", ";\n  transition: all 0.25s ease-in-out;\n\n  &.active {\n    color: ", ";\n\n    &:after {\n      width: calc(100% + 60px);\n      left: -30px;\n      right: -30px;\n    }\n  }\n\n  &:after {\n    content: \"\";\n    position: absolute;\n\n    top: 28px;\n    left: 50%;\n    background-color: ", ";\n    width: 0px;\n    transition: all 400ms ease-in-out;\n    height: 1px;\n    ",
    "\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.secondaryColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.goColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.goColor;
}, responsive_1.media.mobile(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      top: 36px;\n  "], ["\n      top: 36px;\n  "]))));
var AppContentHeaderNavigation = design_library_1.styled.nav(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background-color: ", ";\n  grid-area: content-header;\n  width: 100vw;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  flex-flow: row nowrap;\n  font-family: ", ";\n  font-weight: normal;\n  font-size: 10px;\n  line-height: 14px;\n  letter-spacing: normal;\n  color: ", ";\n  border-bottom: 1px solid ", ";\n  text-transform: uppercase;\n  position: fixed;\n  z-index: 1000;\n\n  ", "\n"], ["\n  background-color: ", ";\n  grid-area: content-header;\n  width: 100vw;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  flex-flow: row nowrap;\n  font-family: ", ";\n  font-weight: normal;\n  font-size: 10px;\n  line-height: 14px;\n  letter-spacing: normal;\n  color: ", ";\n  border-bottom: 1px solid ", ";\n  text-transform: uppercase;\n  position: fixed;\n  z-index: 1000;\n\n  ",
    "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.mainColor;
}, function (props) { return props.theme.fonts.nunito; }, function (props) { return props.theme.colors.secondaryColor; }, function (props) { return props.theme.colors.borderColor; }, responsive_1.media.desktop(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    background-color: ", ";\n    width: 100%;\n    height: 30px;\n  "], ["\n    background-color: ", ";\n    width: 100%;\n    height: 30px;\n  "])), function (props) { return props.theme.colors.mainBg; }));
var AppContentNavigation = function (_a) {
    var links = _a.links;
    return (React.createElement(AppContentHeaderNavigation, null, links &&
        links.map(function (link, index) { return (React.createElement(AppContentHeaderNavLink, { key: index, exact: link.exact, to: link.to }, link.content)); })));
};
exports.default = Object.assign(Content, {
    Header: exports.ContentHeader,
    Body: exports.ContentBody,
    Navigation: AppContentNavigation,
    Action: exports.ContentHeaderActions
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

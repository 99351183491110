import * as React from "react"
import ButtonKey from "../common/ButtonKey"
import { styled } from "@envisioning/design-library"
import { KeyCodeMap, KeyCodeMapReverse } from "~/types"
import media from "~/media"
import { MetricQuestionOption } from "~/graphql"
import { delay, getScoreDisplayLabel } from "~/lib"

interface Props {
  keyCode: KeyCodeMap
  option: MetricQuestionOption
  submitAnswer: (optionId: string) => void
  selected?: boolean
}

export const ScoreButtonStyled = styled(ButtonKey)`
  width: 20%;
  min-width: unset;
  display: flex;
  justify-content: center;

  ${media.query.desktop`
    width: unset;
    min-width: 6.2rem;
    justify-content: space-between;
  `}

  &:hover {
    cursor: pointer;
  }
`

export default function ScoreButton({ option, keyCode, submitAnswer }: Props) {
  const [isSelected, setSelected] = React.useState(false)
  const isDesktop = media.isDesktop()

  async function onSubmit() {
    setSelected(true)
    await submitAnswer(option.id)
  }

  return (
    <ScoreButtonStyled
      kind={isSelected ? "default" : "neutral"}
      minWidth={62}
      keyCode={keyCode}
      onClick={() => onSubmit()}
      onKeyPress={() => onSubmit()}
      buttonLabel={getScoreDisplayLabel(option.score)}
      keyPressLabel={KeyCodeMapReverse[keyCode]}
      noKey={!isDesktop}
      big
    />
  )
}

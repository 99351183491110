import { Loader, Button, Icon, styled } from "@envisioning/design-library"
import * as qs from "query-string"
import * as React from "react"
import { RouteComponentProps } from "react-router-dom"
import { authStore } from "../../store/auth.store"
import { Params } from "../common/withAuth"
import { Row, Col, Container } from "react-grid-system"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${props => props.theme.colors.sidebarColor};
  text-align: center;
`

const MainText = styled.div`
  color: ${props => props.theme.colors.secondaryBg};
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`

type QueryParsed = {
  token?: string
}

export default function AuthScreen(props: RouteComponentProps<Params>) {
  const { surveyId } = props.match.params
  const [confirmed, setConfirmed] = React.useState(false)
  const [error, setError] = React.useState("")

  React.useEffect(() => {
    confirm()
  }, [confirmed])

  async function confirm() {
    const query = props.location.search
    const queryParsed: QueryParsed = qs.parse(query)

    if (!queryParsed.token) {
      return setError("Invalid request")
    }

    if (!confirmed) {
      try {
        const res = await authStore.postConfirmEmail(queryParsed.token)

        if (res.data.error) {
          return setError(res.data.message)
        }

        setConfirmed(true)
      } catch (err) {
        setError(err.message)
      }
    }
  }

  async function onLogin() {
    props.history.push(`/${surveyId}`)
  }

  if (error) {
    return (
      <Wrapper>
        <MainText>{error}</MainText>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Container>
        {!confirmed ? (
          <React.Fragment>
            <MainText> Veryfing your email </MainText>
            <div>
              <Loader type="ball-beat" />
            </div>
          </React.Fragment>
        ) : (
          <Row>
            <Col sm={12}>
              <Icon name="checkC25" color="goColor" size={45} style={{ display: "inline-block" }} />
            </Col>
            <Col sm={12} style={{ padding: "20px 0px" }}>
              <MainText>Your email was sucessfully verified </MainText>
            </Col>
            <Col sm={12} lg={12}>
              <Button big onClick={onLogin}>
                click here to login
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  )
}

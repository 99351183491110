"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var use_media_1 = require("use-media");
var logo = require("../../../../../assets/logo-light.png");
var responsive_1 = require("../../../../util/responsive");
var Navbar_styles_1 = require("./Navbar.styles");
var NavbarLink_1 = require("./NavbarLink");
function Navbar(props) {
    var links = props.links, appName = props.appName, rest = __rest(props, ["links", "appName"]);
    var isDesktop = use_media_1.useMedia({ minWidth: responsive_1.breakpoints.desktop });
    return (React.createElement(Navbar_styles_1.NavbarWrapper, __assign({}, rest),
        React.createElement(Navbar_styles_1.NavbarLogoWrapper, null,
            React.createElement(Navbar_styles_1.NavbarLogo, { src: logo })),
        React.createElement(Navbar_styles_1.NavbarApp, null, appName.toUpperCase()),
        React.createElement(Navbar_styles_1.NavbarItems, null, links &&
            links.map(function (link) {
                if (link.component) {
                    return link.component;
                }
                else {
                    return React.createElement(NavbarLink_1.default, __assign({ key: link.to }, link));
                }
            }))));
}
exports.default = Navbar;

import * as React from "react"
import { useGetAllSurveysQuery } from "~/graphql"
import { ListItem, Heading, Button, Loader } from "@envisioning/design-library"
import { Container } from "react-grid-system"
import { surveyState } from "~/store/survey.store"
import { observer } from "mobx-react-lite"
import { RouteComponentProps } from "react-router-dom"

export const SurveyAdminListing = observer((props: RouteComponentProps<{}>) => {
  const { data, loading } = useGetAllSurveysQuery({ fetchPolicy: "network-only" })
  const [surveyId, setSurveyId] = React.useState(null)

  return (
    <Container>
      <Heading as="h3">Available Surveys</Heading>

      <Button
        onClick={() => {
          props.history.push(`/admin/create-survey`)
        }}
        style={{ marginBottom: 20, marginRight: 12 }}
      >
        create new survey
      </Button>
      <Button
        onClick={() => {
          props.history.push(`/admin/metrics`)
        }}
        style={{ marginBottom: 20 }}
      >
        metrics list
      </Button>

      {loading ? (
        <Loader type={"ball-beat"} style={{ width: "100%", marginTop: 30 }} />
      ) : (
        data.surveys.map((survey, index) => (
          <ListItem
            key={index}
            label={survey.name}
            secondaryLabel={survey.slug}
            onClick={() => {
              props.history.push(`/admin/survey/${survey.id}`)
            }}
            style={{ cursor: "pointer" }}
            data-cy-survey-list
          />
        ))
      )}
    </Container>
  )
})

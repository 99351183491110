import * as React from "react"
import { Button, TextEditor, TextInput } from "@envisioning/design-library"
import { PanelCollapsable } from "../PanelCollapsable"
import { DeleteButtonWrapper, FieldWrapper } from "./MetricForm"
import { ResultLabelFields } from "./OneRangeForm"

interface Props {
  mode: string
  values: any
  scoreArray: number[]
  errors: any
  setFieldValue?: (position: string, value: any) => void
}
interface QuestionProps {
  index: number
  question: any
  mode: string
  errors: any
  updateOption: (option: object, position: string) => void
  updateQuestion: (question: object, position: string) => void
  deleteQuestion: (index: number) => void
}

const QuestionFields = React.memo(
  ({
    index,
    question,
    mode,
    errors,
    updateOption,
    updateQuestion,
    deleteQuestion
  }: QuestionProps) => {
    const [questionTouched, setQuestionTouched] = React.useState({
      label: false,
      description: false
    })
    const questionPosition = `questions[${index}]`

    return (
      <PanelCollapsable title={`${index + 1}. question - ${question.label}`} startCollapsed>
        <FieldWrapper>
          <TextInput
            name={`questions[${index}].label`}
            label="label"
            placeholder="question"
            type="text"
            value={question.label}
            error={
              questionTouched.label &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].label
            }
            onBlur={() => setQuestionTouched({ ...questionTouched, label: true })}
            onChange={value => {
              updateQuestion({ ...question, label: value }, questionPosition)
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextEditor
            name={`questions[${index}].description`}
            label="description"
            placeholder="description (question)"
            initialValue={question.description}
            onChange={value => {
              updateQuestion({ ...question, description: value }, questionPosition)
            }}
          />
        </FieldWrapper>
        {question.options &&
          question.options.map((option, optionIndex) => {
            const [optionTouched, setOptionTouched] = React.useState({
              label: false,
              description: false
            })
            const optionPosition = `questions[${index}].options[${optionIndex}]`

            return (
              <PanelCollapsable
                title={`${optionIndex + 1}. option - ${option.label}`}
                key={optionIndex}
              >
                <FieldWrapper>
                  <TextInput
                    name={`questions[${index}].options[${optionIndex}].label`}
                    label="label"
                    placeholder="option"
                    type="text"
                    value={option.label}
                    error={
                      optionTouched.label &&
                      errors.questions &&
                      errors.questions[index] &&
                      errors.questions[index].options[optionIndex] &&
                      errors.questions[index].options[optionIndex].label
                    }
                    onBlur={() => setOptionTouched({ ...optionTouched, label: true })}
                    onChange={value => {
                      updateOption({ ...option, label: value }, optionPosition)
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextInput
                    name={`questions[${index}].options[${optionIndex}].description`}
                    label="description"
                    placeholder="option"
                    type="text"
                    value={option.description}
                    error={
                      optionTouched.description &&
                      errors.questions &&
                      errors.questions[index] &&
                      errors.questions[index].options[optionIndex] &&
                      errors.questions[index].options[optionIndex].description
                    }
                    onBlur={() => setOptionTouched({ ...optionTouched, description: true })}
                    onChange={value => {
                      updateOption({ ...option, description: value }, optionPosition)
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextInput
                    name="option score"
                    placeholder="score"
                    label="option"
                    type="text"
                    value={option.score}
                    disabled
                  />
                </FieldWrapper>
              </PanelCollapsable>
            )
          })}
        {mode === "create" && (
          <DeleteButtonWrapper>
            <Button
              type="button"
              kind="cancel"
              icon={"x15"}
              onClick={() => deleteQuestion(index)}
            />
          </DeleteButtonWrapper>
        )}
      </PanelCollapsable>
    )
  },
  (prev, next) =>
    prev.question.description == next.question.description &&
    prev.question.label == next.question.label &&
    prev.index == next.index &&
    prev.updateOption === next.updateOption &&
    prev.updateQuestion === next.updateQuestion &&
    prev.deleteQuestion === next.deleteQuestion
)

function SpecificRangeForm({ mode, values, scoreArray, errors, setFieldValue }: Props) {
  React.useEffect(() => {
    if (!values.resultLabels || values.resultLabels.length === 0) {
      setFieldValue(
        "resultLabels",
        scoreArray.map(resultLabelScore => {
          return { label: "", description: "", score: resultLabelScore }
        })
      )
    }

    if (mode === "edit") {
      const newOptions = values.questions[0].options.map(option => {
        return { label: option.label, description: option.description, score: option.score }
      })
      setFieldValue("options", newOptions)
    }
  }, [])

  function createQuestion() {
    const newOptions = scoreArray.map(optionScore => {
      return { label: "", description: "", score: optionScore }
    })
    const newQuestion = {
      label: "",
      description: "",
      options: newOptions,
      key: values.questions ? values.questions.length + 1 : 1
    }
    setFieldValue("questions", [...(values.questions ? values.questions : []), newQuestion])
  }

  function deleteQuestion(deletedIndex) {
    const newQuestions = values.questions.filter((_, index) => index !== deletedIndex)
    setFieldValue("questions", newQuestions)
  }

  function updateQuestion(updatedQuestion, questionPosition) {
    setFieldValue(questionPosition, updatedQuestion)
  }

  function updateOption(updatedOption, optionPosition) {
    setFieldValue(optionPosition, updatedOption)
  }

  function updateResultLabel(updatedResultLabel, resultLabelPosition) {
    setFieldValue(resultLabelPosition, updatedResultLabel)
  }

  return (
    <>
      <PanelCollapsable title="questions">
        {values.questions &&
          values.questions.map((question, index) => {
            return (
              <QuestionFields
                key={question.key}
                index={index}
                question={question}
                mode={mode}
                errors={errors}
                updateOption={updateOption}
                updateQuestion={updateQuestion}
                deleteQuestion={deleteQuestion}
              />
            )
          })}
        {mode === "create" && (
          <Button
            type="button"
            onClick={createQuestion}
            style={{
              marginTop: values.questions && values.questions.length ? 10 : 0
            }}
          >
            create question
          </Button>
        )}
      </PanelCollapsable>
      <PanelCollapsable title="result labels">
        {values.resultLabels &&
          values.resultLabels.map((resultLabel, index) => {
            return (
              <ResultLabelFields
                key={resultLabel.score}
                resultLabel={resultLabel}
                index={index}
                updateResultLabel={updateResultLabel}
                errors={errors}
              />
            )
          })}
      </PanelCollapsable>
    </>
  )
}

export default SpecificRangeForm

import * as React from "react"
import {
  Button,
  ListItem,
  Loader,
  styled,
  Switch,
  Text,
  TextEditor,
  TextInput
} from "@envisioning/design-library"
import { surveyFormValidator } from "@shared/validations"
import { Formik } from "formik"
import { SurveyCommonFieldsFragment, useGetAllMetricsQuery } from "~/graphql"
import { PanelCollapsable } from "../PanelCollapsable"

interface Props {
  survey?: SurveyCommonFieldsFragment
  values?: SurveyCommonFieldsFragment
  mode: string
  submitForm: (values: any) => void
}

const FieldWrapper = styled.div`
  margin-bottom: 0.5rem;
`

const SwitchWrapper = styled.div`
  height: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.2rem;
`

const SurveyForm: React.SFC<Props> = ({ survey, mode, submitForm }) => {
  const { data, loading } = useGetAllMetricsQuery({ fetchPolicy: "network-only" })

  const initializeValues = survey => {
    if (survey) {
      survey.boardId = survey.board.id
      survey.projectId = survey.project.id
      return survey
    }

    return {
      darkLabelsOnImageEnabled: false,
      labelsOnImageEnabled: false,
      metrics: []
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues(survey)}
      validationSchema={surveyFormValidator}
      onSubmit={async (values, { setSubmitting }) => {
        const surveyInput: Partial<any> = {
          name: values.name,
          slug: values.slug,
          description: values.description,
          workgroupImageId: values.workgroupImageId,
          workgroupName: values.workgroupName,
          imageId: values.imageId,
          metricPageText: values.metricPageText,
          authPageImageId: values.authPageImageId,
          authPageText: values.authPageText,
          emailLoginTitleText: values.emailLoginTitleText,
          darkLabelsOnImageEnabled: values.darkLabelsOnImageEnabled,
          labelsOnImageEnabled: values.labelsOnImageEnabled
        }

        if (mode === "create") {
          surveyInput.metricsId = values.metrics
          surveyInput.projectId = values.projectId
          surveyInput.boardId = values.boardId
          surveyInput.config = {
            authStrategies: ["email_only"]
          }
        }
        if (mode === "edit") surveyInput.id = survey.id

        await submitForm(surveyInput)
        setSubmitting(false)
      }}
      render={({
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        errors,
        isValid,
        touched
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FieldWrapper>
              <TextInput
                name="name"
                placeholder="name"
                label="name"
                type="text"
                value={values.name}
                onChange={value => setFieldValue("name", value)}
                error={touched.name && errors.name}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="slug"
                placeholder="slug"
                label="slug"
                type="text"
                value={values.slug}
                onChange={value => setFieldValue("slug", value)}
                error={touched.slug && errors.slug}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextEditor
                name="description"
                placeholder="description"
                label="description"
                initialValue={values.description}
                error={errors.description}
                onChange={markdown => {
                  setFieldValue("description", markdown)
                }}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="workgroupImageId"
                placeholder="workgroup image id"
                label="workgroup image id"
                type="text"
                value={values.workgroupImageId}
                onChange={value => setFieldValue("workgroupImageId", value)}
                error={touched.workgroupImageId && errors.workgroupImageId}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="workgroupName"
                placeholder="workgroup name"
                label="workgroup name"
                type="text"
                value={values.workgroupName}
                onChange={value => setFieldValue("workgroupName", value)}
                error={touched.workgroupName && errors.workgroupName}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="imageId"
                placeholder="image id"
                label="image id"
                type="text"
                value={values.imageId}
                onChange={value => setFieldValue("imageId", value)}
                error={touched.imageId && errors.imageId}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextEditor
                name="metricPageText"
                placeholder="metric page text"
                label="metric page text"
                initialValue={values.metricPageText}
                error={errors.metricPageText}
                onChange={markdown => {
                  setFieldValue("metricPageText", markdown)
                }}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="authPageImageId"
                placeholder="auth page image id"
                label="auth page image id"
                type="text"
                value={values.authPageImageId}
                onChange={value => setFieldValue("authPageImageId", value)}
                error={touched.authPageImageId && errors.authPageImageId}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextEditor
                name="authPageText"
                placeholder="auth page text"
                label="auth page text"
                initialValue={values.authPageText}
                error={errors.authPageText}
                onChange={markdown => {
                  setFieldValue("authPageText", markdown)
                }}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="emailLoginTitleText"
                placeholder="email login title text"
                label="email login title text"
                type="text"
                value={values.emailLoginTitleText}
                onChange={value => setFieldValue("emailLoginTitleText", value)}
                error={touched.emailLoginTitleText && errors.emailLoginTitleText}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="projectId"
                placeholder="project id"
                label="project id"
                type="text"
                value={values.projectId}
                onChange={value => setFieldValue("projectId", value)}
                error={touched.projectId && errors.projectId}
                onBlur={handleBlur}
                disabled={isSubmitting || mode === "edit"}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextInput
                name="boardId"
                placeholder="board id"
                label="board id"
                type="text"
                value={values.boardId}
                onChange={value => setFieldValue("boardId", value)}
                error={touched.boardId && errors.boardId}
                onBlur={handleBlur}
                disabled={isSubmitting || mode === "edit"}
              />
            </FieldWrapper>
            <SwitchWrapper>
              <Text style={{ width: "unset", marginRight: 10 }}>dark labels on image:</Text>
              <Switch
                checked={values.darkLabelsOnImageEnabled}
                onChange={value => setFieldValue("darkLabelsOnImageEnabled", value)}
                data-cy-survey-darkLabelsOnImage
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <Text style={{ width: "unset", marginRight: 10 }}>labels on image:</Text>
              <Switch
                checked={values.labelsOnImageEnabled}
                onChange={value => setFieldValue("labelsOnImageEnabled", value)}
                data-cy-survey-labelsOnImage
              />
            </SwitchWrapper>
            {mode === "create" && (
              <PanelCollapsable startCollapsed={false} title="add metrics">
                {loading ? (
                  <Loader type={"ball-beat"} style={{ width: "100%", marginTop: 30 }} />
                ) : (
                  <>
                    {data.metrics.map(metric => (
                      <ListItem
                        label={metric.name}
                        secondaryLabel=""
                        style={{ cursor: "pointer" }}
                        renderAction={
                          <Switch
                            data-cy-survey-metricSwitch
                            onChange={value => {
                              if (value) {
                                const currentMetrics = values.metrics
                                if (!currentMetrics.includes(metric.id)) {
                                  currentMetrics.push(metric.id)
                                }
                                setFieldValue("metrics", currentMetrics)
                              } else {
                                const metricIndex = values.metrics.indexOf(metric.id)
                                values.metrics.splice(metricIndex, 1)
                              }
                            }}
                          />
                        }
                      />
                    ))}
                  </>
                )}
              </PanelCollapsable>
            )}
            <Button
              fullWidth
              big
              type="submit"
              disabled={isSubmitting || !isValid}
              style={{ marginTop: 20 }}
              loading={isSubmitting}
            >
              save survey changes
            </Button>
          </form>
        )
      }}
    />
  )
}

export default SurveyForm

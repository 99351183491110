import * as React from "react"
import { Button, Dropdown, styled, TextInput, TextEditor } from "@envisioning/design-library"
import { Formik } from "formik"
import { omit } from "lodash"
import { MetricFieldsFragment, UpdateMetricInput, UpdateMetricQuestionInput } from "~/graphql"
import { PanelCollapsable } from "../PanelCollapsable"
import OneRangeForm from "./OneRangeForm"
import SpecificRangeForm from "./SpecificRangeForm"
import { oneRangeMetricValidator, specificRangeMetricValidator } from "@shared/validations"

interface Props {
  metric?: Partial<MetricFieldsFragment>
  metricIndex?: number
  mode: string
  submitForm?: (values: any) => void
}

const calculationMethodProps = {
  name: "text",
  label: "calculation method",
  placeholder: "calculation method"
}

const pollModelProps = {
  name: "text",
  label: "poll model",
  placeholder: "poll model"
}

export const PanelWrapper = styled.div`
  margin-bottom: 2.5rem;
`

export const FieldWrapper = styled.div`
  margin-bottom: 0.5rem;
`

export const DeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
`

const MetricForm = ({ metric, submitForm, mode }: Props) => {
  function initializeValues(metric) {
    if (metric) return metric
    return {
      scoreRange: { min: undefined, max: undefined }
    }
  }

  function getScoreArray(min, max) {
    return Array(max - min + 1)
      .fill(min)
      .map((n, i) => n + i)
  }

  const [scoreMin, setScoreMin] = React.useState(undefined)
  const [scoreMax, setScoreMax] = React.useState(undefined)
  const [pollModel, setPollModel] = React.useState(undefined)
  const [disableScore, setDisableScore] = React.useState(false)
  const scoreArray = !isNaN(scoreMin) && !isNaN(scoreMax) ? getScoreArray(scoreMin, scoreMax) : []

  const dropdownOptions = {
    calculationMethod: [
      { value: "artithmetic_avg" },
      { value: "harmonic_mean" },
      { value: "interquartile_mean" }
    ],
    pollModel: [{ value: "one_range" }, { value: "specific_range" }]
  }

  function getValidationSchema() {
    if (pollModel === "specific_range") return specificRangeMetricValidator
    return oneRangeMetricValidator
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues(metric)}
      validationSchema={getValidationSchema()}
      onSubmit={async (values, { setSubmitting }) => {
        const metricInput: Partial<UpdateMetricInput> = {
          name: values.name,
          description: values.description,
          calculationMethod: values.calculationMethod,
          pollModel: values.pollModel,
          scoreRange: {
            min: parseInt(scoreMin),
            max: parseInt(scoreMax)
          },
          questions: values.questions.map(question => {
            const questionMap: Partial<UpdateMetricQuestionInput> = {
              label: question.label,
              description: question.description,
              options:
                values.pollModel === "one_range"
                  ? values.options
                  : question.options.map(o => omit(o, "__typename"))
            }
            if (mode === "edit" && values.pollModel === "one_range") {
              questionMap.options = question.options.map(option => {
                const editedOption = values.options.find(o => o.score === option.score)
                return { ...editedOption, id: option.id }
              })
            }
            if (mode === "edit") questionMap.id = question.id
            return questionMap
          }),
          resultLabels: values.resultLabels.map(r => omit(r, "__typename"))
        }
        if (mode === "edit") metricInput.id = metric.id

        await submitForm(metricInput)
        setSubmitting(false)
      }}
      render={({
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        errors,
        isValid,
        touched
      }) => {
        if (!isNaN(values.scoreRange.min) && !isNaN(values.scoreRange.max)) {
          setScoreMin(values.scoreRange.min)
          setScoreMax(values.scoreRange.max)
          setDisableScore(true)
        }

        return (
          <form onSubmit={handleSubmit}>
            <FieldWrapper>
              <TextInput
                name="name"
                placeholder="metric name"
                label="metric name"
                type="text"
                value={values.name}
                error={touched.name && errors.name}
                disabled={isSubmitting}
                onChange={value => setFieldValue("name", value)}
                onBlur={handleBlur}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextEditor
                name="description"
                label="metric description"
                placeholder="metric description"
                initialValue={values.description}
                error={errors.description}
                onChange={markdown => {
                  setFieldValue("description", markdown)
                }}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Dropdown
                {...calculationMethodProps}
                defaultValue={values.calculationMethod}
                onChange={value => setFieldValue("calculationMethod", value)}
                disabled={mode === "edit"}
                data-cy-metric-calculationMethod
              >
                {dropdownOptions.calculationMethod.map(option => (
                  <Dropdown.Option key={option.value} value={option.value}>
                    {option.value}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            </FieldWrapper>
            <FieldWrapper>
              <Dropdown
                {...pollModelProps}
                defaultValue={values.pollModel}
                onChange={value => {
                  setFieldValue("pollModel", value)
                  setPollModel(value)
                }}
                disabled={mode === "edit" || disableScore}
                data-cy-metric-pollModel
              >
                {dropdownOptions.pollModel.map(option => (
                  <Dropdown.Option key={option.value} value={option.value}>
                    {option.value}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            </FieldWrapper>

            {values.pollModel && mode === "create" && (
              <>
                <PanelCollapsable title="score range" startCollapsed>
                  <FieldWrapper>
                    <TextInput
                      name="scoreRangeMin"
                      placeholder="score range"
                      label="min"
                      type="number"
                      value={scoreMin}
                      onChange={value => setScoreMin(parseInt(value))}
                      disabled={disableScore}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <TextInput
                      name="scoreRangeMax"
                      placeholder="score range"
                      label="max"
                      type="number"
                      value={scoreMax}
                      onChange={value => setScoreMax(parseInt(value))}
                      disabled={disableScore}
                    />
                  </FieldWrapper>
                </PanelCollapsable>
                {!disableScore && (
                  <Button
                    type="button"
                    onClick={() => setDisableScore(true)}
                    style={{ marginTop: 12, marginBottom: 12 }}
                    disabled={!scoreArray.length}
                  >
                    save score range
                  </Button>
                )}
              </>
            )}

            {disableScore && (
              <>
                {values.pollModel && values.pollModel == "one_range" ? (
                  <OneRangeForm
                    mode={mode}
                    values={values}
                    scoreArray={scoreArray}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                ) : (
                  <SpecificRangeForm
                    mode={mode}
                    values={values}
                    scoreArray={scoreArray}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}
              </>
            )}

            <Button
              fullWidth
              big
              type="submit"
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
              style={{ marginTop: 20 }}
            >
              save metric changes
            </Button>
          </form>
        )
      }}
    />
  )
}

export default MetricForm

import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { fetch as fetchPolyfill } from "whatwg-fetch"

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id
})

const createClient = () =>
  new ApolloClient({
    cache,
    link: new HttpLink({
      credentials: "include",
      uri: process.env.GRAPHQL_ENDPOINT,
      fetch: fetchPolyfill,
      fetchOptions: {
        fetchOptions: {
          credentials: "include"
        }
      }
    })
  })

export const apolloClient = createClient()

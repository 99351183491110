import { styled, Summary } from "@envisioning/design-library"
import * as React from "react"
import media from "../../media"
import Markdown from "../common/Markdown"
import Divider from "../common/Divider"

interface Props {
  description: string
  summary: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${media.query.mobile`
    padding: 30px 20px 0;
  `}

  ${media.query.tablet`
    padding: 30px 50px 0;
  `}
`

const StyledSummary = styled(Summary)`
  ${media.query.desktop`
    font-size: 16px;
    line-height: 22px;
  `}
`

const SurveyTechnology: React.SFC<Props> = ({ description, summary }) => {
  return (
    <Wrapper>
      <StyledSummary>{summary}</StyledSummary>
      <Divider />
      <Markdown markdown={description} />
    </Wrapper>
  )
}

export default SurveyTechnology

import { styled } from "@envisioning/design-library"
import * as React from "react"
import { RouteComponentProps } from "react-router"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${props => props.theme.colors.sidebarColor};
`

const MainText = styled.div`
  color: ${props => props.theme.colors.secondaryBg};
  font-size: 16px;
  max-width: 35rem;
  text-align: center;
  line-height: 22px;
`

export default function MainScreen(props: RouteComponentProps) {
  return (
    <Wrapper>
      <MainText>This is the main page, talk to the organizer and ask the survey url.</MainText>
    </Wrapper>
  )
}

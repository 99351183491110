import * as React from "react"
import GlobalStyles from "@ev/platform/lib/modules/layout/globalStyles"
import { Route, Switch, withRouter } from "react-router-dom"
import routerConfig from "./router.config"
import AuthScreen from "./components/auth/AuthScreen"
import AuthVerify from "./components/auth/AuthVerify"
import MainScreen from "./components/common/MainScreen"
import SurveyIntro from "./components/survey/SurveyIntro"
import SurveyList from "./components/survey/SurveyList"
import SurveyMetric from "./components/survey/SurveyMetric"
import withAuth from "./components/common/withAuth"
import withSurvey from "./components/common/withSurvey"
import SurveyModal from "./components/survey/SurveyModal"
import { SurveyAdmin } from "./components/admin/SurveyAdmin"
import SettingsModal from "./components/survey/SettingsModal"
import { surveyState } from "./store/survey.store"
import { observer } from "mobx-react-lite"
import { AppGlobalStyles } from "./styles"
import { RouteComponentProps } from "react-router"
import { ToastContainer } from "react-toastify"

const SurveyContainer = observer(() => {
  return surveyState.surveyId ? <SurveyModal /> : null
})

const SettingsModalContainer = observer(() => {
  return surveyState.surveyId ? (
    <SettingsModal surveyId={surveyState.surveyId} isOpen={surveyState.isSettingsOpen} />
  ) : null
})

const App = (props: RouteComponentProps) => {
  const isAdmin = props.location.pathname.substr(0, 6) === "/admin"
  return (
    <React.Fragment>
      <GlobalStyles />
      <AppGlobalStyles />
      <Switch>
        <Route exact path={"/"} component={MainScreen} />
        <Route exact path={"/:surveyId/auth"} component={withSurvey(AuthScreen)} />
        <Route exact path={"/:surveyId/auth/verify"} component={withSurvey(AuthVerify)} />

        {isAdmin ? (
          <Route exact path={routerConfig.admin.to} component={SurveyAdmin} />
        ) : (
          <>
            <Route exact path={routerConfig.home.to} component={withAuth(SurveyIntro)} />
            <Route exact path={routerConfig.survey.to} component={withAuth(SurveyList)} />
            <Route exact path={routerConfig.metric.to} component={withAuth(SurveyMetric)} />
          </>
        )}
      </Switch>
      <SurveyContainer />
      <SettingsModalContainer />
      <ToastContainer autoClose={1700} />
    </React.Fragment>
  )
}

export default withRouter(App)
